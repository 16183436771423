export const currFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

export const floatFormat = (value) => {
  let newValue = value.toString().replace('R$ ', '');
  let noDots = newValue.replaceAll('.', '');
  let noComma = noDots.replace(',', '.');
  return Number(noComma);
};

export const mascaraMoeda = (value) => {
  const reversed = (val) => val.split('').reverse().join('');
  let valor = reversed(value.toString().replace(/[^\d]+/gi, ''));
  let resultado = '';
  let mascara = reversed('###.###.###.###,##');
  for (let x = 0, y = 0; x < mascara.length && y < valor.length; ) {
    if (mascara.charAt(x) !== '#') {
      resultado += mascara.charAt(x);
      x++;
    } else {
      resultado += valor.charAt(y);
      y++;
      x++;
    }
  }
  value = reversed(resultado);
  return `R$ ${value}`;
};

export const data_br = (data) => {
  return data ? new Date(data).toLocaleDateString('pt-BR') : '';
};

export const datahora = (data) => {
  return data
    ? `${new Date(data).toLocaleDateString('pt-BR')} ${new Date(
        data,
      ).toLocaleTimeString('pt-BR')}`
    : '';
};

export function capitalizeFirstLetter(string) {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAllFirstLetters(string) {
  string = string.toLowerCase();
  return string.replace(/\b\w/g, (l) => l.toUpperCase());
}

export function formatCEP(cep) {
  return `${cep.slice(0, 5)}-${cep.slice(5)}`;
}

export function formatPronoums(string) {
  let replaced = string.replaceAll('De', 'de');
  replaced = replaced.replaceAll('Da', 'da');
  replaced = replaced.replaceAll('Do', 'do');
  replaced = replaced.replaceAll('Das', 'das');
  replaced = replaced.replaceAll('Dos', 'dos');
  return replaced;
}

export function formatCapitalizeFirst(endereco) {
  let newEndereco = capitalizeAllFirstLetters(endereco);
  newEndereco = formatPronoums(newEndereco);
  return newEndereco;
}

export async function getCEP(CEP, num = null) {
  if (!CEP) return null;

  let sanitizedCEP = CEP.replaceAll('-', '');
  const res = await fetch(`https://viacep.com.br/ws/${sanitizedCEP}/json/`);
  const data = await res.json();
  if (data.erro) {
    alert('CEP não encontrado');
  }

  return {
    logradouro: formatCapitalizeFirst(data.logradouro),
    bairro: formatCapitalizeFirst(data.bairro),
    localidade: formatCapitalizeFirst(data.localidade),
    uf: data.uf,
    full: `${formatCapitalizeFirst(data.logradouro)}, ${
      num ? `nº ${num}, ` : null
    } bairro ${formatCapitalizeFirst(data.bairro)}, ${formatCapitalizeFirst(
      data.localidade,
    )}-${data.uf}, CEP ${CEP}`,
  };
}

export function getFirstWord(string) {
  return string.split(' ')[0];
}