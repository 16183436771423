// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Financeiro() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Financeiro</h4>

      <p>financeiro por mes no plano free: 5</p>

      <p>financeiro por mes no plano trial: 5</p>

      <p>a pagar ou receber  por mes no plano basic: 15</p>

      <p>a pagar ou receber  por mes no plano pro: 100</p>

      <p>a pagar ou receber  por mes no plano premium: 500</p>

    </div>
  );
}
