import { ThreeDots } from 'react-loader-spinner';
import logo from '../assets/img/logo.png';

export default function LoadingFull({ class_op }) {
  return (
    <div
      className={`setWidh mAuto my-5 d-flex flex-column justify-content-center align-items-center faCenter ${class_op}`}
    >
      <img
        src={logo}
        className='w50 wpMax300 mt-5'
        alt='logo'
      />
      <ThreeDots
        height='100'
        width='100'
        color='#007dca'
        ariaLabel='bars-loading'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
      />
      <p className='mt-4 mb-2 f09  softxt'>Aguarde...</p>
    </div>
  );
}
