import React, { useState } from 'react';
import { gapi } from 'gapi-script';
import GoogleListDocuments from './GoogleListDocuments';
import GoogleDriveImage from '../../assets/img/app/google-drive.png';
import { Button } from 'react-bootstrap';

export default function StorageServices({
  tenant,
  showSignOut = false,
  showSignIn = false,
  showSeeFiles = false,
}) {
  const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
  const [listDocumentsVisible, setListDocumentsVisibility] = useState(false);
  const [signedInUser, setSignedInUser] = useState();
  // const [accessToken, setAccessToken] = useState();

  const [documents, setDocuments] = useState([]);
  const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] =
    useState(false);

  const DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  ];
  const SCOPES =
    'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive';

  const handleAuthClick = async () => {
    try {
      const auth = await gapi.auth2.getAuthInstance().signIn();
      // const token = auth.xc.access_token;
      // setAccessToken(token);
      console.log(auth);
    } catch (error) {
      console.error('Erro ao autenticar:', error);
    } finally {
      setIsLoadingGoogleDriveApi(false);
    }
  };

  const handleSignOutClick = async () => {
    try {
      setListDocumentsVisibility(false);
      await gapi.auth2.getAuthInstance().signOut();
    } catch (error) {
      console.error('Erro ao sair:', error);
    } finally {
      setIsLoadingGoogleDriveApi(false);
    }
  };

  const listFiles = (searchTerm = null) => {
    setIsFetchingGoogleDriveFiles(true);
    gapi.client.drive.files
      .list({
        pageSize: 100,
        fields:
          'nextPageToken, files(id, name, mimeType, modifiedTime, parents)',
        q: searchTerm,
      })
      .then(function (response) {
        setIsFetchingGoogleDriveFiles(false);
        setListDocumentsVisibility(true);
        const res = JSON.parse(response.body);
        setDocuments(res.files);
      });
  };

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      // const u = gapi.auth2.getAuthInstance().currentUser;
      setSignedInUser(gapi.auth2.getAuthInstance().currentUser.le.wt);
      // setAccessToken(
      //   gapi.auth2.getAuthInstance().currentUser.le.xc.access_token,
      // );
      listFiles();
      setIsLoadingGoogleDriveApi(false);
    } else {
      handleAuthClick();
    }
  };

  const uploadFile = async (file) => {
    if (!file) {
      alert('Por favor, selecione um arquivo primeiro!');
      return;
    }

    const form = new FormData();
    form.append(
      'metadata',
      new Blob(
        [
          JSON.stringify({
            name: file.file.name,
          }),
        ],
        { type: 'application/json' },
      ),
    );
    form.append('file', file.file);

    try {
      const accessToken = gapi.auth.getToken().access_token;
      await fetch(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
          body: form,
        },
      );
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
    } finally {
      listFiles();
    }
  };

  const initClient = () => {
    setIsLoadingGoogleDriveApi(true);
    gapi.client
      .init({
        apiKey: 'AIzaSyC7Muf_tcSxtHxKxa10DvPmht0V4BBgmDM',
        clientId:
          '1002271216197-0bmflsf5q32kv6q4iqe1g6n1t5tieuou.apps.googleusercontent.com',
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        function () {
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function (error) {
          console.log(error);
        },
      );
  };

  const handleClientLoad = () => {
    gapi.load('client:auth2', initClient);
  };

  const showDocuments = () => {
    setListDocumentsVisibility(true);
  };

  const onClose = () => {
    setListDocumentsVisibility(false);
  };

  const deleteFile = async (fileId) => {
    if (!fileId) {
      return;
    }

    try {
      const accessToken = gapi.auth.getToken().access_token;
      await fetch(`https://www.googleapis.com/drive/v2/files/${fileId}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });
    } catch (error) {
      console.error('Erro ao deletar arquivo:', error);
    }

    listFiles();
  };

  const openFile = (fileId) => {
    if (!fileId) {
      return;
    }

    const url = `https://drive.google.com/file/d/${fileId}/view`;
    window.open(url, '_blank');
  };

  if (isLoadingGoogleDriveApi) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <GoogleListDocuments
        visible={listDocumentsVisible}
        onClose={onClose}
        documents={documents}
        onSearch={listFiles}
        signedInUser={signedInUser}
        onSignOut={handleSignOutClick}
        isLoading={isFetchingGoogleDriveFiles}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        openFile={openFile}
      />

      <div
        onClick={() => handleClientLoad()}
        className='source-container'
      >
        <div className='d-flex flex-row align-items-center'>
          <img
            // height='80'
            width='30'
            src={GoogleDriveImage}
            alt='Google Drive'
            aria-label='Google Drive'
          />
          <span className='ms-1 fs09'>Google Drive</span>

          {showSeeFiles && (
            <Button
              onClick={showDocuments}
              size='sm'
              variant='primary'
            >
              Ver arquivos
            </Button>
          )}

          {showSignIn && (
            <Button
              className='mx-2'
              onClick={handleAuthClick}
              size='sm'
              variant='primary'
            >
              Autenticar
            </Button>
          )}

          {showSignOut && (
            <Button
              className='ms-2'
              onClick={handleSignOutClick}
              size='sm'
              variant='danger'
            >
              Sair
            </Button>
          )}
        </div>
      </div>

      {/* {filtered.map((service) => (
        <div
          key={service.CODIGO}
          className='my-2 d-flex flex-row align-content-center'
        >
          <p className='ffSoft me-2'>{service.NAME}</p>
          <p className='ms-1'>
            {service.tenantService ? (
              <Button
                size='sm'
                variant='danger'
                onClick={() =>
                  unlinkServices(service.tenantService.SERVICE_CODIGO)
                }
              >
                Desvincular Conta
              </Button>
            ) : (
              <Button
                size='sm'
                variant='success'
                onClick={() => linkAccount(tenant, service.NAME)}
              >
                Vincular Conta
              </Button>
            )}
          </p>
        </div>
      ))} */}

      {/* <div
        onClick={() => handleClientLoad()}
        className='source-container'
      >
        <div className='icon-container'>
          <div className='icon icon-success'>
            <img
              height='80'
              width='80'
              src=''
            />
          </div>
        </div>
        <div className='content-container'>
          <p className='title'>Google Drive</p>
          <span className='content'>
            Import documents straight from your google drive
          </span>
        </div>
      </div> */}
    </div>
  );
}
