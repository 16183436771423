import { useEffect, useState } from 'react';

import './packages/wookie/assets/css';

import './assets/css/_root_.css';

import { CookiesProvider } from 'react-cookie';
import GlobalProvider from './context/GlobalProvider';
import PackageProvider from './packages/wookie/context/PackageProvider';
import LoadingFull from './components/LoadingFull';

import BewookRoutes from './routes/Routes';

export default function App() {
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    }
    setLoading(false);
  }, []);

  if (Loading) {
    return <LoadingFull />;
  }

  return (
    <CookiesProvider>
      <PackageProvider>
        <GlobalProvider>
          <BewookRoutes />
        </GlobalProvider>
      </PackageProvider>
    </CookiesProvider>
  );
}
