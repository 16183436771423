import { useContext, useEffect, useState } from 'react';
import LoadingFull from '../../components/LoadingFull';
import { GlobalContext } from '../../context/Context';

function Logout() {
  const [loading, setLoading] = useState(false);
  const { logout } = useContext(GlobalContext);

  useEffect(() => {
    const handleLogout = async () => {
      setLoading(true);
      logout();
      setLoading(false);
    };
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFull />;
  return null;
}

export default Logout;
