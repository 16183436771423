import API from './requests';
import { SERVICES } from './endpoints';

export async function services() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(SERVICES.read.all, {
    token,
  });
  return response;
}
