import React, { useEffect, useContext, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import NavBar from '../../components/NavBar';
import { GlobalContext } from '../../context/Context';
import Loading from '../../packages/wookie/common/Loading';
import ContainerBox from '../../packages/wookie/box/ContainerBox';
import { MP } from '../../services/endpoints';
import API from '../../services/requests';

initMercadoPago('TEST-be1fef3b-8d8a-43a8-a9b7-3101280f7948', {
  locale: 'pt-BR',
});

export default function Wallet() {
  const { subscription } = useParams();
  const location = useLocation();
  const payload = location.state?.payload;

  const { checkLogin, store, navigate } = useContext(GlobalContext);
  const logged = checkLogin();
  const { user, email, cpf, telefone, cep, endnum, address, assinantes } =
    store();
  const [selection, setSelection] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [initialization, setInitialization] = useState({});
  const [customization, setCustomization] = useState({});
  // const [preference, setPreference] = useState(null);

  const [isReady, setIsReady] = useState(false);

  const onSubmit = async (formData) => {
    const newPayload = {
      ...formData,
      subscription_code: subscription,
      plan_id: payload.assinante.PLANO_PADRAO,
      frequency: payload.assinante.FREQUENCIA_MESES,
      description: payload.assinante.PLANO_NOME,
      email,
      name: user,
      phone_ddd: telefone.slice(1, 3),
      phone_number: telefone.slice(5),
      zip_code: cep,
      street_name: address.logradouro,
      street_number: endnum,
    };

    console.log(newPayload)

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${MP.checkout.process.base}${MP.checkout.process.endpoint}`;
    form.target = '_blank';

    for (const key in newPayload) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = newPayload[key];
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();

    document.body.removeChild(form);

    //   fetch(`${MP.checkout.process.base}${MP.checkout.process.endpoint}`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(newPayload),
    //   })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       resolve();
    //     })
    //     .catch((error) => {
    //       reject();
    //     });
    // });
  };

  const onError = async (error) => {
    console.log(error);
  };
  const onReady = async () => {};

  useEffect(() => {
    if (!logged) navigate('/login');
    setIsLoading(true);
    const getInfo = async () => {
      try {
        const meliPlanos = await API(MP.core.planos.read.all, {});

        const assinante = assinantes.find(
          (assinante) => assinante.ASSINANTE_CODIGO === subscription,
        );
        const plan = meliPlanos.data.results.find(
          (plano) => plano.external_reference === assinante.PLANO_CODIGO,
        );

        setSelection({ plan, assinante, subscription });

        setInitialization({
          amount: plan ? plan.transaction_amount : 0,
          payer: {
            name: user,
            email,
            identification: {
              type: 'CPF',
              number: cpf,
            },
            phone: {
              area_code: telefone.slice(1, 3),
              number: telefone.slice(5),
            },
          },
        });

        setCustomization({
          visual: {
            style: {
              customVariables: {
                theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
              },
            },
            texts: {
              formTitle: 'Cartão de Crédito',
            },
          },
          paymentMethods: {
            types: {
              excluded: ['debit_card'],
            },
            maxInstallments: 1,
          },
        });

        // const response = await API(END.checkout.preference.create, {
        //   plan: plan.reason,
        //   transaction_amount: plan.transaction_amount,
        // });

        // setPreference(response.data.id);
        setIsReady(true);
      } catch (error) {
        console.log(error);
      }
    };

    getInfo();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Loading />;

  return (
    <ContainerBox
      navbar={<NavBar logged={logged} />}
      classMain='my-3 d-flex flex-column justify-content-start align-items-start'
      main={
        <>
          <div className='my-5 d-flex flex-column justify-content-start align-items-start container'>
            <h5 className='ffSoft'>Meu Plano</h5>

            <div className='row fs10 w90 wpMax450 m-2 mt-3'>
              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>Plano</span>
                <span>{selection.plan ? selection.plan.reason : ''}</span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>Valor</span>
                <span>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    selection.plan ? selection.plan.transaction_amount : 0,
                  )}
                </span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>Endereço</span>
                <span>
                  {address && address.logradouro}, {endnum}, CEP {cep}
                </span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>Nome</span>
                <span>{user}</span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>CPF</span>
                <span>{cpf}</span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>Telefone</span>
                <span>
                  {telefone.slice(1, 3)} {telefone.slice(5)}
                </span>
              </p>

              <p className='gorow border-bottom border-secondary px-2 pb-1'>
                <span className='wp100 textContrastLow'>E-mail</span>
                <span>{email}</span>
              </p>
            </div>

            <button
              className='btn btn-danger mt-3 btn-sm fs10'
              onClick={() => {
                console.log('Cancelar Assinatura');
              }}
            >
              Cancelar Assinatura
            </button>

            <div className='mt-5 mAuto'>
              {isReady && (
                <CardPayment
                  initialization={initialization}
                  customization={customization}
                  onSubmit={onSubmit}
                  onReady={onReady}
                  onError={onError}
                />
              )}
            </div>
          </div>
        </>
      }
    />
  );
}
