import React, { useContext, useEffect, useState } from 'react';
import {
  Document,
  Header,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImageRun,
  Packer,
  Paragraph,
  PatchType,
  TextRun,
} from 'docx';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import { getBlob } from '../../../services/tenants';
import { JurisContext } from '../../../context/Context';
import { patchDocument } from 'docx';

export default function Timbrado() {
  const { jurisStore, logoParams } = useContext(JurisContext);
  const [blob, setBlob] = useState(null);
  const [timbrado, setTimbrado] = useState(null);
  const { token, infoTenant } = jurisStore();

  const { TENANT_CODIGO, TENANT_TENANT } = JSON.parse(infoTenant);

  const NEW = 'NEW';
  const EXISTING = 'EXISTING';

  useEffect(() => {
    const blobBD = async () => {
      const b = await getBlob(
        TENANT_CODIGO,
        'TENANTS',
        'LOGO',
        TENANT_CODIGO,
        TENANT_TENANT,
        token,
      );
      setBlob(b);
      const t = await getBlob(
        TENANT_CODIGO,
        'TENANTS',
        'TIMBRADO',
        TENANT_CODIGO,
        TENANT_TENANT,
        token,
      );
      setTimbrado(t);
    };
    blobBD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDocx = (option) => {
    if (option === NEW) {
      const doc = new Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  top: 800,
                  right: 1000,
                  bottom: 800,
                  left: 1300,
                },
              },
            },
            headers: {
              default: new Header({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: blob,
                        transformation: {
                          width: logoParams.width * 1.2,
                          height: logoParams.height * 1.2,
                          rotation: -90,
                        },
                        floating: {
                          horizontalPosition: {
                            relative:
                              HorizontalPositionRelativeFrom.INSIDE_MARGIN,
                            align: HorizontalPositionAlign.CENTER,
                          },
                          verticalPosition: {
                            offset: 1714400,
                          },
                        },
                      }),
                    ],
                  }),
                ],
              }),
            },

            // footers: {
            //   default: new Footer({
            //     children: [new Paragraph('Footer text')],
            //   }),
            // },
            children: [new Paragraph('')],
          },
        ],
      });
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, 'document.docx');
      });
    } else {
      const arrayBufferData = timbrado;

      patchDocument(arrayBufferData, {
        outputType: 'nodebuffer',
        patches: {
          content: {
            type: PatchType.PARAGRAPH,
            children: [new TextRun('')],
          },
        },
      }).then((finaldata) => {
        const newTimb = new Blob([finaldata], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        saveAs(newTimb, 'timbrado.docx');
      });
      return;
    }
  };

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Timbrado</h4>
      {blob && (
        <Button
          variant='primary'
          size='sm'
          className='me-2'
          onClick={() => generateDocx(NEW)}
        >
          Criar novo
        </Button>
      )}
      {timbrado && (
        <Button
          variant='secondary'
          size='sm'
          className='me-2'
          onClick={() => generateDocx(EXISTING)}
        >
          Usar existente
        </Button>
      )}
    </div>
  );
}
