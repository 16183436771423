import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { GlobalContext } from '../../context/Context';

import CONF from '../../config';
import LoadingDots from '../../packages/wookie/common/LoadingDots';
import Wrapper from '../../packages/wookie/misc/Wrapper';
import PackageContext from '../../packages/wookie/context/PackageContext';
import { IconsUI, ui } from '../../packages/wookie/icons';
import { IMaskInput } from 'react-imask';
import { otp } from '../../services/auth';

export default function Login() {
  const [errorMessage, setErrorMessage] = useState();
  const [keyMessage, setKeyMessage] = useState();
  const [email, setEmail] = useState();
  const [cpf, setCpf] = useState();
  const [password, setPassword] = useState();

  const { navigate, login, loading, setLoading, svc, redirectPath } =
    useContext(GlobalContext);
  const { theme } = useContext(PackageContext);

  const v = svc === 'dash' ? 1 : 0;

  const userlogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setKeyMessage('');
    const res = await fetch('https://api.ipify.org?format=json');
    const data = await res.json();
    const { ip } = data;

    // const email = document.getElementById('email').value;
    // const password = document.getElementById('password').value;

    try {
      const response = await login({ email, password, v, ip });
      const { success, message, key } = response;
      if (!success) {
        setErrorMessage(message);
        setKeyMessage(key);
      } else {
        setLoading(false);
        v ? navigate('/admin') : navigate(redirectPath, { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const sendOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setKeyMessage('');

    if (!cpf) {
      setErrorMessage('CPF é obrigatório');
      setLoading(false);
      return;
    }

    try {
      const response = await otp(email, cpf);
      const { success, message, key } = response.data;
      console.log(response);
      if (!success) {
        setErrorMessage(message);
        setKeyMessage(key);
      } else {
        setKeyMessage('CONFIRMATION_SENT');
        setErrorMessage(message);
      }
      document.getElementById('passwordGroup').classList.remove('divHide');
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (keyMessage === 'USER_EMAIL_NOT_CONFIRMED') {
      document.getElementById('actionButton').classList.remove('divHide');
      document.getElementById('cpfGroup').classList.remove('divHide');
      document.getElementById('passwordGroup').classList.add('divHide');
    }
  }, [keyMessage]);

  if (loading) return <LoadingDots conf={CONF} />;

  return (
    <Wrapper
      conf={CONF}
      text={
        <Form
          data-bs-theme={theme}
          className='mt-3 w90 wpMin300 wpMax400'
        >
          <p className='mt-5 fs12'>Login</p>

          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='email'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              E-mail
            </Form.Label>
            <Form.Control
              type='email'
              id='email'
              autoComplete='email'
              name='email'
              aria-describedby='email'
              onChange={(event) => setEmail(event.target.value)}
              className='fs10'
            />
          </Form.Group>

          <Form.Group
            className='mb-3 divHide'
            id='cpfGroup'
          >
            <Form.Label
              htmlFor='cpf'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              CPF
            </Form.Label>
            <Form.Control
              required
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              name='cpf'
              autoComplete='cpf'
              aria-describedby='cpf mask-cpf'
              className='fs10'
              onChange={(event) => setCpf(event.target.value)}
            />
          </Form.Group>

          <InputGroup
            className='mb-3'
            id='passwordGroup'
          >
            <Form.Control
              placeholder='senha'
              aria-label='senha'
              aria-describedby='senha'
              type='password'
              id='password'
              name='password'
              autoComplete='current-password'
              className='fs10'
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              variant='outline-secondary'
              id='button-senha'
            >
              <IconsUI
                info={ui.misc.eye.on}
                clsnm='svg10'
                click={() => {
                  const input = document.getElementById('password');
                  input.type === 'password'
                    ? (input.type = 'text')
                    : (input.type = 'password');
                }}
              />
            </Button>
          </InputGroup>
          <p className='text-center mt-3 mb-2 fs08 cRed fw600'>
            {errorMessage}
          </p>
          <div
            className='w100 mb-4 divHide'
            id='actionButton'
          >
            <Button
              size='sm'
              className='w100'
              onClick={(event) => sendOTP(event)}
            >
              Reenviar e-mail de confirmação
            </Button>
          </div>

          <div className='d-flex flex-row justify-content-around mt-4 mb-2'>
            <Link to='/recover'>
              <Button
                className='no_under'
                variant='link'
                size='sm'
              >
                Esqueci a senha
              </Button>
            </Link>
            <Button
              variant='primary'
              onClick={(event) => userlogin(event)}
              size='sm'
            >
              Efetuar Login
            </Button>
          </div>

          <div className='text-center mt-4'>
            <Link to='/register'>
              <Button
                className='no_under'
                variant='link'
                size='sm'
              >
                Primeiro acesso? Cadastre-se
              </Button>
            </Link>
          </div>
        </Form>
      }
    />
  );
}
