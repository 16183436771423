import React, { useContext, useEffect } from 'react';
import JurisProvider from '../../context/JurisProvider';
import {GlobalContext} from '../../context/Context';
import RenderSection from '../../components/juris/RenderSection';
import SideBar from '../../components/juris/SideBar';
import '../../assets/css/juris.css';
import NavBar from '../../components/NavBar';
import TenantBar from '../../components/juris/TenantBar';

export default function Juris() {
  const { setProduto } = useContext(GlobalContext);

  useEffect(() => {
    setProduto('juris');
  }, [setProduto]);

  return (
    <JurisProvider>
      <NavBar />
      <TenantBar />
      <main className='container'>
        <div className='d-flex flex-row w100'>
          <section className='main-container-sidebar sidebar-juris'>
            <SideBar />
          </section>
          <section className='main-container-render mt-2'>
            <RenderSection />
          </section>
        </div>
      </main>
    </JurisProvider>
  );
}
