// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Flows() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Flows</h4>

      <p>
        flows por mes no plano free: 1
      </p>

      <p>
        flows por mes no plano trial: 1
      </p>

      <p>
        flows por mes no plano basic: 15
      </p>

      <p>
        flows por mes no plano pro: 100
      </p>

      <p>
        flows por mes no plano premium: ilimitado
      </p>

    </div>
  );
}
