// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Contratos() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Contratos</h4>

      <p>contratos por mes no plano free: 0</p> 
      <p>contratos por mes no plano trial: 0</p>

      <p>contratos por mes no plano basic: 3</p>

      <p>contratos por mes no plano pro: 25</p>

      <p>contratos por mes no plano premium: ilimitado</p>

    </div>
  );
}
