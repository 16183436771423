import ContainerBox from '../../packages/wookie/box/ContainerBox';
import NavBar from '../../components/NavBar';

export default function ToDoPrivacy() {
  return (
    <ContainerBox
      navbar={<NavBar />}
      navreturn={true}
      main={
        <div className='container'>
          <h4 className='ffSoft mb-5'>Política de Privacidade </h4>
          <p>
            <strong>Última Atualização: 01/01/2025</strong>
          </p>

          <p>
            Bem-vindo à política de privacidade da Bewook! A proteção de seus
            dados é uma prioridade para nós. Este documento explica como
            coletamos, usamos, armazenamos e compartilhamos as informações
            fornecidas por você.
          </p>

          <h5 className='textContrastColor ffSoft'>1. Dados Coletados</h5>
          <p>
          Nós não coletamos, armazenamos ou compartilhamos nenhuma informação pessoal ou dados de qualquer natureza dos nossos usuários. O nosso aplicativo não armazena dados como nome, e-mail, localização ou qualquer outro tipo de dado pessoal.
          </p>

          <h5 className='textContrastColor ffSoft'>2. Uso do Aplicativo</h5>
          <p>Nosso aplicativo não exige a criação de uma conta ou o fornecimento de informações pessoais para o uso. Os dados utilizados durante a utilização do aplicativo não são armazenados, processados ou compartilhados de forma alguma.</p>
         
          <h5 className='textContrastColor ffSoft'>
            3. Serviços de Terceiros
          </h5>
          <p>Nosso aplicativo pode integrar serviços de terceiros, como o Google Play para distribuições, mas não coletamos ou acessamos dados desses serviços. Recomendamos que você consulte as políticas de privacidade dos serviços de terceiros, caso deseje mais informações.</p>
         
          <h5 className='textContrastColor ffSoft'>
            4. Cookies e Tecnologias Similares
          </h5>
          <p>
          Nosso aplicativo não utiliza cookies ou qualquer outra tecnologia de rastreamento para coletar informações sobre seu comportamento ou preferências.
          </p>
          
          <h5 className='textContrastColor ffSoft'>
            5. Alterações na Política de Privacidade
          </h5>
          <p>
            Reservamo-nos o direito de alterar esta política a qualquer momento.
            Notificaremos sobre mudanças significativas através de email ou no
            próprio site.
          </p>

          <h5 className='textContrastColor ffSoft'>8. Contato</h5>
          <p>
            Se tiver dúvidas ou preocupações sobre esta política, entre em
            contato conosco:
          </p>
          <ul>
            <li>
              Para questões relacionadas à LGPD:{' '}
              <a href='mailto:lgpd@bewook.com'>lgpd@bewook.com</a>
            </li>
            <li>
              Para suporte: <a href='mailto:help@bewook.com'>help@bewook.com</a>
            </li>
          </ul>
        </div>
      }
    />
  );
}
