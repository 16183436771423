import React from 'react';
import logo from '../../assets/img/logo.png';

import CONF from '../../config';
import Wrapper from '../../packages/wookie/misc/Wrapper';

export default function Page404() {
  return (
    <Wrapper
      conf={CONF}
      showLogo={false}
      text={
        <div className=' '>
          <a href='/'>
            <img
              src={logo}
              className='w70 wpMax350 my-5'
              alt='logo'
            />
          </a>
          <p className='fs16 pt-3'>Ooops! Página não encontrada</p>
          <div className='my-3'>
            <a href='/'>Voltar para a página inicial</a>
          </div>
        </div>
      }
    />
  );
}
