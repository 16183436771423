import React, { useCallback, useContext, useState } from 'react';
// import moment from 'moment';
import { debounce } from 'lodash';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InputGroup from 'react-bootstrap/InputGroup';
import DragAndDrop from '../DragAndDrop';
import PackageContext from '../../packages/wookie/context/PackageContext';
import { IC, ui, IconsUI } from '../../packages/wookie/icons';
import LoadingDots from '../../packages/wookie/common/LoadingDots';
import CONF from '../../config';

export default function GoogleListDocuments({
  visible,
  onClose,
  documents = [],
  onSearch,
  signedInUser,
  onSignOut,
  uploadFile,
  deleteFile,
  openFile,
  isLoading,
}) {
  const { theme } = useContext(PackageContext);

  const [files, setFiles] = useState([]);
  const [updated, setUpdated] = useState(false);

  const search = (value) => {
    delayedQuery(`name contains '${value}'`);
  };

  //eslint-disable-next-line
  const delayedQuery = useCallback(
    debounce((q) => onSearch(q), 500),
    [],
  );

  if (isLoading) {
    return (
      <Offcanvas
        show={visible}
        onHide={onClose}
        data-bs-theme={theme}
        className='oc-gdrive'
        placement='end'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Arquivos do Google Drive</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <LoadingDots conf={CONF} />
        </Offcanvas.Body>
      </Offcanvas>
    );
  }

  return (
    <Offcanvas
      show={visible}
      onHide={onClose}
      data-bs-theme={theme}
      className='oc-gdrive'
      placement='end'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Arquivos do Google Drive</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className='d-flex flex-column my-3'>
          <div className='d-flex flex-row align-items-center'>
            <span className='me-3'>
              Logado como: {`${signedInUser?.Ad} (${signedInUser?.cu})`}
            </span>
            <Button
              variant='danger'
              size='sm'
              onClick={onSignOut}
              className='fs10'
            >
              Desconectar conta
            </Button>
          </div>

          <div className='table-card-actions-container'>
            <div className='table-search-container'>
              <InputGroup className='my-3'>
                <Form.Control
                  placeholder='Search Google Drive'
                  aria-label='Search Google Drive'
                  aria-describedby='search-gdrive'
                  onChange={(e) => search(e.target.value)}
                  onSearch={(value) => search(value)}
                />
                <InputGroup.Text id='search-gdrive'>
                  <IC.CgSearchLoading />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <Table
            data-bs-theme={theme}
            striped
            hover
            size='sm'
            responsive
            className='vAlignMiddle tableTransparent bgTransparent'
          >
            <thead>
              <tr className='textContrastLow fw300'>
                <th className='textContrastLow fw300 py-2'>Nome</th>
                <th className='textContrastLow fw300 py-2'>
                  Data de Modificação
                </th>
                <th className='textContrastLow fw300 py-2'></th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc, index) => (
                <tr key={index}>
                  <td>{doc.name}</td>
                  <td>
                    {new Date(doc.modifiedTime).toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }) +
                      ' ' +
                      new Date(doc.modifiedTime).toLocaleTimeString('pt-BR', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                  </td>

                  <td>
                    <span className='gorow'>
                      {/* <span>{doc.id}</span> */}
                      <IconsUI
                        info={ui.action.magnifier}
                        clsnm='mx-2 cLink svg12'
                        click={() => {
                          openFile(doc.id);
                        }}
                        tooltip='Abrir arquivo'
                        sideDivClass='divIconsUi mousePointerHover'
                      />

                      <IconsUI
                        info={ui.action.download}
                        clsnm='mx-2 cLink svg12'
                        click={() => {
                          console.log('Download arquivo', doc);
                        }}
                        tooltip='Download arquivo'
                        sideDivClass='divIconsUi mousePointerHover'
                      />

                      <IconsUI
                        info={ui.action.delete.trash}
                        clsnm='mx-2 cRed svg10'
                        click={() => {
                          deleteFile(doc.id);
                        }}
                        tooltip='Remover arquivo'
                        sideDivClass='divIconsUi mousePointerHover'
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Form style={{ marginTop: 20 }}>
            <DragAndDrop
              filesSt={files}
              onFilesSelected={(files) => {
                setFiles(files);
              }}
              upd={updated}
            />

            <div className='d-flex flex-row justify-content-between my-3'>
              <Button
                variant='secondary'
                size='sm'
                onClick={() => {
                  setFiles([]);
                  setUpdated(!updated);
                  onClose();
                }}
              >
                Cancelar
              </Button>

              <Button
                variant='primary'
                size='sm'
                onClick={async () => {
                  try {
                    for (const file of files) {
                      await uploadFile(file);
                    }
                  } catch (error) {
                    console.error('Erro ao fazer upload do arquivo', error);
                  } finally {
                    setFiles([]);
                    setUpdated(!updated);
                  }
                }}
              >
                Upload
              </Button>
            </div>
          </Form>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
