import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';
import '../../assets/css/master-sidebar.css';
import '../../assets/css/components/navbar.css';
import {JurisContext} from '../../context/Context';
import IconsUI, { ui } from '../IconsUI';

export default function SideBar() {
  const { setSection } = useContext(JurisContext);
  // const { navigate, logout } = useContext(GlobalContext);

  // const handleLogout = () => {
  //   logout();
  //   navigate('/login');
  // };

  return (
    <div className='container-sidebar'>
      <div className='d-flex flex-row '>
        <button
          variant='dark'
          size='sm'
          className='button-side'
          onClick={() => {
            const labels = document.querySelectorAll('.sp-label-sidebar');
            labels.forEach((label) => {
              label.classList.toggle('div-hide');
            });
          }}
        >
          <IconsUI
            info={ui.misc.menu}
            clsnm='svg12'
          />
          <span className='ms-1 f09 sp-label-sidebar'>Menu</span>
        </button>
      </div>
      <div className='mt-2'>
        <div className='d-flex flex-column align-items-start'>
          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('home')}
          >
            <IconsUI
              info={ui.misc.house}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Home</span>
          </button>

          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('painel')}
          >
            <IconsUI
              info={ui.misc.dash}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Painel</span>
          </button>
          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('clientes')}
          >
            <IconsUI
              info={ui.user.reg}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Clientes</span>
          </button>
          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('contratos')}
          >
            <IconsUI
              info={ui.misc.products}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Contratos</span>
          </button>
          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('processos')}
          >
            <IconsUI
              info={ui.misc.starlight}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Processos</span>
          </button>

          {/* 
          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('subscribers')}
          >
            <IconsUI
              info={ui.money.subscriber}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Assinantes</span>
          </button> */}

          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('faturas')}
          >
            <IconsUI
              info={ui.money.invoice}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Faturas</span>
          </button>

          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('financeiro')}
          >
            <IconsUI
              info={ui.money.payment}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Financeiro</span>
          </button>

          <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => setSection('settings')}
          >
            <IconsUI
              info={ui.misc.settings}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Settings</span>
          </button>

          {/* <button
            variant='outline-dark'
            size='sm'
            className='button-side '
            onClick={() => handleLogout()}
          >
            <IconsUI
              info={ui.misc.logout}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Sair</span>
          </button> */}
        </div>
      </div>
    </div>
  );
}
