import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IMaskInput } from 'react-imask';
import { register } from '../../services/auth';

import CONF from '../../config';
import Wrapper from '../../packages/wookie/misc/Wrapper';
import PackageContext from '../../packages/wookie/context/PackageContext';

export default function Register() {
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { theme, navigate } = useContext(PackageContext);

  const registry = async (event) => {
    event.preventDefault();

    try {
      const response = await register(cpf, nome, email, telefone, password);

      const data = response.data;
      const { message } = data;
      if (data.user_found) {
        setMessage(
          'Já existe um usuário com os dados fornecidos. Efetue login ou recupere a senha.',
        );
      } else {
        if (message === 'Criado') {
          setMessage('Usuário criado com sucesso! Você já pode fazer login.');
        } else {
          setMessage(message);
        }
      }
    } catch (error) {
      console.log(error);
      setMessage(error.response.data.message);
    }
  };

  return (
    <Wrapper
      conf={CONF}
      text={
        <Form
          data-bs-theme={theme}
          className='mt-3 w90 wpMin300 wpMax400'
          autoComplete='on'
        >
          <Form.Group className='mb-2'>
            <Form.Label
              htmlFor='nome'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              Nome
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='nome'
              autoComplete='name'
              className='fs10'
              onChange={({ target: { value } }) => setNome(value)}
            />
          </Form.Group>

          <Form.Group className='mb-2'>
            <Form.Label
              htmlFor='telefone'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              WhatsApp
            </Form.Label>
            <Form.Control
              required
              type='text'
              as={IMaskInput}
              mask='(00) 00000-0000'
              id='telefone'
              autoComplete='phone'
              className='fs10'
              onChange={({ target: { value } }) => setTelefone(value)}
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label
              htmlFor='cpf'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              CPF
            </Form.Label>
            <Form.Control
              required
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              name='cpf'
              autoComplete='cpf'
              aria-describedby='cpf mask-cpf'
              onChange={({ target: { value } }) => setCpf(value)}
              className='fs10'
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label
              htmlFor='email'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              E-Mail
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='email'
              name='email'
              autoComplete='email'
              aria-describedby='email'
              onChange={({ target: { value } }) => setEmail(value)}
              className='fs10'
            />
          </Form.Group>
          <Form.Group className='mb-2'>
            <Form.Label
              htmlFor='password'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              Password
            </Form.Label>
            <Form.Control
              required
              type='password'
              id='password'
              className='fs10'
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </Form.Group>
          <p className='my-3 faCenter cRed fw600 fs10'>{message}</p>
          <Button
            variant='primary'
            className='my-2'
            size='sm'
            onClick={(event) => registry(event)}
          >
            Registrar
          </Button>
          <Button
            variant='link'
            size='sm'
            className='noUnder cLink fs10'
            onClick={() => navigate('/login')}
          >
            Já tem cadastro? Faça login
          </Button>
        </Form>
      }
    />
  );
}
