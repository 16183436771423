import React, { useContext } from 'react';
import { PadContext } from '../../context/Context';
// import MasterContext from '../../context/MasterContext';
// import Users from './Users';
// import Tenants from './Tenants';
// import Home from './Home';
// import Invoices from './Invoices';
// import Products from './Products';
// import Settings from './Settings';
// import Payments from './Clientes';
import Painel from './sections/Painel';
import Cadastros from './sections/Cadastros';

import Settings from './settings/Settings';
import Agenda from './sections/Agenda';
import Financeiro from './sections/Financeiro';
import Contratos from './sections/Contratos';
import Calculos from './sections/Calculos';
import Flows from './sections/Flows';
import Tasks from './sections/Tasks';

export default function RenderSection() {
  const { section } = useContext(PadContext);

  switch (section) {
    case 'cadastros':
      return <Cadastros />;
    case 'calendar':
      return <Agenda />;
      case 'tasks':
        return <Tasks />;
    case 'settings':
      return <Settings />;
    case 'painel':
      return <Painel />;
    case 'flows':
      return <Flows />;
    case 'financeiro':
      return <Financeiro />;
    case 'contratos':
      return <Contratos />;
    case 'calculos':
      return <Calculos />;
    default:
      return <Painel />;
  }
}
