import { ui } from '../packages/wookie/icons';

const dev = true;

let serviceName = window.location.hostname.replace('.bewook.com', '');

const hostnameConfig = {
  'signer': {
    web: 'signer.bewook.com',
    name: 'signer',
  },
    default: {
    web: 'bewook.com',
    name: 'portal',
  },
};

if (!dev) {

  if (window.location.hostname === 'localhost:8974') serviceName = 'bewook';
  if (window.location.hostname === 'localhost:8975') serviceName = 'signer';
}

const service = hostnameConfig[serviceName] || hostnameConfig['default'];
const hostname = 'bewook.com';

const setup = {
  name: 'bewook',
  whatsapp: '(11) 97544-6100)',
  phone: '11975446100',
  phone_formatted: '(11) 97544-6100)',
  email: 'help@bewook.com',
  title: 'bewook',
  instagram: '',
  twitter: '',
  telegram: 'bewookbot',
  ddi: '55',
};

const config = {
  tenant: 'bewook',
  company: 'bewook',
  brand: 'bewook',
  headline: 'Soluções inteligentes em software',
  description: 'Soluções inteligentes em software',
  site: 'bewook.com',
};

const { tenant, company, brand, headline, site } = config;

const meta = {
  type: 'Organization',
  imageLink: `https://www.bewook.com/logo.png`,
  imageType: 'image/png',
  imageWidth: 450,
  imageHeight: 117,
  description: 'Soluções inteligentes em software',
  keywords: 'Soluções inteligentes em software',
  author: tenant,
  robots: 'index, follow',
};

const images = {
  light: {
    logo: require(`../assets/img/logo.png`),
    logotext: require(`../assets/img/logotext.png`),
    calc: require(`../assets/img/logo/light/calc.png`),
    juris: require(`../assets/img/logo/light/juris.png`),
    signer: require(`../assets/img/logo/light/signer.png`),
    arch: require(`../assets/img/logo/light/arch.png`),
    clinic: require(`../assets/img/logo/light/clinic.png`),
    habita: require(`../assets/img/logo/light/habita.png`),
    manage: require(`../assets/img/logo/light/manage.png`),
    pad: require(`../assets/img/logo/light/pad.png`),
  },
  dark: {
    logo: require(`../assets/img/logo.png`),
    logotext: require(`../assets/img/logotext.png`),
    calc: require(`../assets/img/logo/dark/calc.png`),
    juris: require(`../assets/img/logo/dark/juris.png`),
    signer: require(`../assets/img/logo/dark/signer.png`),
    arch: require(`../assets/img/logo/dark/arch.png`),
    clinic: require(`../assets/img/logo/dark/clinic.png`),
    habita: require(`../assets/img/logo/dark/habita.png`),
    manage: require(`../assets/img/logo/dark/manage.png`),
    pad: require(`../assets/img/logo/dark/pad.png`),
  },
  logo: require(`../assets/img/logotext.png`),
  logotext: require(`../assets/img/logotext.png`),
  w: require(`../assets/img/w.png`),
};
images.normal = images.light;

const links = {
  site: `https://www.${site}`,
  instagram: `https://www.instagram.com/${setup.instagram}`,
  mail: `mailto:${setup.email}`,
  whatsapp: `https://API.whatsapp.com/send?phone=${setup.ddi}${setup.whatsapp}`,
  phone: `tel:+${setup.ddi}${setup.phone}`,
  login: `/login`,
  register: `/register`,
  logo: `https://www.${site}/img/logotext.png`,
  telegram: `https://t.me/${setup.telegram}`,
};

const objLinks = {
  mail: {
    a: {
      href: `mailto:${setup.email}`,
      class: 'btn outline-secondary',
      aria: 'Envie um email',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.email.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Envie um email',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Envie um email',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  whatsapp: {
    a: {
      href: `https://API.whatsapp.com/send?phone=55${setup.whatsapp}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo WhatsApp',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.whatsapp.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo WhatsApp',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo WhatsApp',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  phone: {
    a: {
      href: `tel:+${setup.ddi}${setup.phone}`,
      class: 'btn outline-secondary',
      aria: 'Ligue para nós',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.phone.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Ligue para nós',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Ligue para nós',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  telegram: {
    a: {
      href: `https://t.me/${setup.telegram}`,
      class: 'btn outline-secondary',
      aria: 'Converse pelo Telegram',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.telegram.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Converse pelo Telegram',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Converse pelo Telegram',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  site: {
    a: {
      href: `https://www.${site}`,
      class: 'btn outline-secondary',
      aria: 'Acesse nosso site',
      targetBlank: true,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.social.site.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Acesse nosso site',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Acesse nosso site',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
  login: {
    a: {
      href: `/login`,
      class: 'btn outline-secondary',
      aria: 'Faça login',
      targetBlank: false,
      rel: 'noreferrer',
    },
    icon: {
      icon: ui.user.lock.icon,
      class: 'svg14 hovGreen',
      click: null,
      tooltip: 'Faça login',
    },
    text: {
      content: null,
      class: null,
    },
    bio: {
      text: 'Faça login em nosso app',
      class: null,
    },
    divClass: 'd-flex flex-row align-items-center',
  },
};

// const bio = {
//   tenant,
//   image: links.logo,
//   items: [...objLinks],
// };

const favicon = `https://www.${site}/favicon.ico`;

const colors = {
  black: '#000000',
  darkest: '#02071b',
  darker: '#070d24',
  dark: '#0d142f',
  grafitte: '#393e46',
  blue: '#3a4776',
  bluer: '#007fd4',
  lightBlue: '#007fd4',
  yellow: '#f3c82e',
  gold: '#d1bb57',
  gray: '#cccccc',
  grey: '#f0f0f0',
  white: '#ffffff',
  bs: {
    blue: '#1e337c',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#d63384',
    red: '#aa0000',
    orange: '#fd7e14',
    yellow: '#ffbb00',
    green: '#2cbe00',
    teal: '#00a372',
    cyan: '#007fd4',
    black: '#000',
    white: '#fff',
    gray: {
      100: '#f8f9fa',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40',
      900: '#212529',
    },
  },
};

const CONF = {
  hostname,
  service,
  config,
  setup,

  tenant,
  company,
  brand,
  headline,
  site,

  meta,
  images,
  links,
  objLinks,
  // bio,

  favicon,
  colors,
  domain: window.location.hostname,
  slash: '/',
};

export default CONF;
