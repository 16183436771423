// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Agenda() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Agenda</h4>

      <p>USERNAME para link direto do usuario: free</p>
      <p>link externo: agenda.bewook.com/usuario: free</p>
      <p>
        limite de inclusoes externas: 1 no plano free,  5 por mes gratis no plano normal, 15 no
        pro, ilimitado no premium
      </p>
      <p>verificar incorporar google Agenda - free</p>
    </div>
  );
}
