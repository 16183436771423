import React, { useEffect, useState } from 'react';
import { get } from '../../../services/requests';
import { apoio } from '../../../services/endpoints';
import '../../../assets/css/indices.css';
import { Button, Form, Table } from 'react-bootstrap';

export default function Indices({ theme }) {
  const [indices, setIndices] = useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    async function fetchIndices() {
      const response = await get(apoio.indices.all);
      let items = response.data.items;
      items =
        items &&
        items.map((item) => {
          let dateref = item.dataref.split('T')[0];
          item.dateref = dateref.split('-').reverse().join('/');
          return item;
        });

      setIndices(items);
      setFiltered(items);
    }
    fetchIndices();
  }, []);

  return (
    <>
      <div className='d-flex flex-row align-items-center my-3'>
        <Form.Label
          htmlFor='searchIndice'
          className='me-2 fs10 m-0'

        >
          Pesquisar data
        </Form.Label>
        <Form.Control
          data-bs-theme={theme}
          type='text'
          size='sm'
          className='form-control wp175'
          id='searchIndice'
          onChange={(e) => {
            const search = e.target.value;
            const filteredSearch = indices.filter((indice) => {
              return indice.dateref.includes(search);
            });
            setFiltered(filteredSearch);
          }}
        />
        <Button
          id='searchClean'
          variant='secondary'
          size='sm'
          className='fs10 ms-2'
          onClick={() => {
            setFiltered(indices);
            document.getElementById('searchIndice').value = '';
          }}
        >
          Limpar
        </Button>
      </div>

      <Table
        striped
        hover
        responsive
        data-bs-theme={theme}
        className='fs10'
      >
        <thead>
          <tr>
            <th>Referência</th>
            <th>TJSP</th>
            <th>IGPM</th>
            <th>INCCDI</th>
            <th>INCCM</th>
            <th>INPC</th>
            <th>IPCA</th>
            <th>IPCAE</th>
          </tr>
        </thead>
        <tbody>
          {filtered &&
            filtered.map((indice) => (
              <tr key={indice.id}>
                <td>{indice.dateref}</td>
                <td>{indice.tjsp}</td>
                <td>{indice.igpm}</td>
                <td>{indice.inccdi}</td>
                <td>{indice.inccm}</td>
                <td>{indice.inpc}</td>
                <td>{indice.ipca}</td>
                <td>{indice.ipcae}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
