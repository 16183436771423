export const ui = {
  social: {
    whatsapp: "whatsapp",
    telegram: "telegram",
    email: "email",
    calendar: {
      reg: "calendar",
      add: "calendar-add",
    },
    phone: {
      reg: "phone",
      color: "phone-color",
    },
    message: "message",
  },
  action: {
    edit: "edit",
    delete: {
      trash: "delete-trash",
      circle: "delete-circle",
    },
    add: {
      color: "add-color",
      reg: "add-reg",
      circle: "add-circle",
    },
    expand: "expand",
    download: "download",
    upload: {
      color: "upload-color",
      reg: "upload-reg",
    },
    arrowdown: "arrowdown",
    magnifier: "magnifier",
    start: "start",
    search: "search",
    back: "back",
    login: "login",
    logout: "logout",
  },
  folder: {
    open: "open",
  },
  file: {
    pdf: "pdf",
    image: "image",
    other: "other",
    attach: "attach",
  },
  etapas: {
    simulacao: "simulação",
    documentacao_comprador: "documentação do comprador",
    aprovacao_credito: "aprovação de crédito",
    documentacao_vendedor: "documentação do vendedor",
    documentacao_imovel: "documentação do imóvel",
    avaliacao_engenharia: "avaliação de engenharia",
    confirmacao_valores: "confirmação de valores",
    assinatura_formularios: "assinatura de formulários",
    analise_juridica: "análise jurídica",
    assinatura_contrato: "assinatura do contrato",
    prefeitura: "prefeitura",
    cartorio: "cartório",
    finalizado: "finalizado",
    mindmap: "mindmap",
  },
  status: {
    waiting: "waiting",
    done: "done",
  },

  user: {
    reg: "user",
    add: "user-add",
    delete: "user-delete",
  },
  misc: {
    cross: "cross",
    check: {
      color: "check",
      circle: "check-circle",
    },
    clock: "clock",
    edit: "edit",
    add_database: "add-database",
    tasks: "tasks",
    star: "star",
    starlight: "starlight",
    house: "house",
    money: "money",
    menu: "menu",
    logout: "logout",
    products: "products",
    dash: "dash",
  },
  money: {
    reg: "money",
    color: "money-color",
    payment: "payment",
    invoice: "invoice",
    subscriber: "subscriber",
    cart: "cart",
  },
}