import React, { useEffect, useState } from 'react';
import '../assets/css/app/blobviewer.css';
import { TENANTS } from '../services/endpoints';
import API from '../services/requests';

export default function BlobImage({
  blobCode,
  blobTable,
  tenantCode,
  tenant,
  token,
  setParams,
}) {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const loadBlobContent = async () => {
      try {
        const form = new FormData();
        let endpoint;
        form.append('codigo', blobCode);
        form.append('tenant', tenant);
        form.append('tenant_codigo', tenantCode);
        form.append('token', token);

        switch (blobTable) {
          case 'TENANTS':
            endpoint = TENANTS.tenant.read.logo;
            break;

          default:
            break;
        }

        const response = await API(endpoint, form, true);
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        if (objectURL) {
          const image = document.getElementById(blobCode);
          // console.log({
          //   image: image,
          //   objectURL: objectURL,
          //   width: image.width,
          //   height: image.height

          // })
          // image.onload = () => {
          const { width, height } = image;
          setParams({
            width,
            height,
          });

          //   console.log({width: image.width,
          //     height: image.height})
          // };

          // setParams(url);
        }
        setUrl(objectURL);
      } catch (error) {
        console.error(error);
      }
    };

    loadBlobContent();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className='content-doc fs08 ffSoft'
      id={`cont_logo_${blobCode}`}
    >
      <img
        src={url}
        alt='img'
        className='img_iframe wpMax250'
        id={blobCode}
      />
    </div>
  );
}
