import { useContext } from 'react';
import ContainerBox from '../packages/wookie/box/ContainerBox';
import { GlobalContext } from '../context/Context';
import NavBar from '../components/NavBar';

export default function Admin() {
  const { checkLogin } = useContext(GlobalContext);

  const logged = checkLogin();

  return (
    <ContainerBox
      navbar={<NavBar logged={logged} />}
      main={
        <>
          <h1>Admin</h1>
        </>
      }
      classMain='my-3'
    />
  );
}
