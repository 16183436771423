import { useContext } from 'react';
import PackageContext from '../context/PackageContext';

export default function Wrapper({
  conf = {},
  text = '',
  clsnmText = '',
  textHeader = '',
  clsnmTextHeader = '',
  showLogo = true,
}) {
  const { theme } = useContext(PackageContext);
  return (
    <div className='wrapper text-center'>
      {showLogo && (
        <a
          href='/'
          rel='noreferrer'
          target='_blank'
          className='logoHeader'
        >
          <img
            src={conf.images[theme].logotext}
            className={`homeLogo mb-1`}
            alt={conf.tenant}
          />
        </a>
      )}
      <div className={`fs18 fw600 ffSoft ${clsnmTextHeader}`}>{textHeader}</div>
      <div className={`mt-1 fs16 ${clsnmText}`}>{text}</div>
    </div>
  );
}
