import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

export default function Loading({
  class_op,
  sm = false,
  messages = [
    'Aguarde...',
    'Estamos consultando suas informações...',
    'Mais um instante, por favor...',
    'Estamos quase lá...',
  ],
}) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    <div className={`w100 gocol align-items-center justify-content-center  p-1 ${class_op}`}>
      <Oval
        visible={true}
        height={sm ? 25 : 50}
        width={sm ? 25 : 50}
        color='#4fa94d'
        ariaLabel='oval-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
      <p className='ffSoft fw500 fs12 pt-3'>
        {messages[currentMessageIndex]}
      </p>
    </div>
  );
}
