export const OCI =
  'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords';

// const HAPI = 'https://api.bewook.com';
const OAPI = 'https://core.bewook.com';
// const CAPI = 'http://localhost:8001';
// const OAPI = 'http://localhost:8000';
const CAPI = 'https://checkout.bewook.com';
// const IAPI = 'https://api.ipify.org';

export const AUTH = {
  login: {
    base: OCI,
    endpoint: '/global/rest/auth/login/',
    method: 'post',
    content: 'json',
  },
  logout: {
    base: OCI,
    endpoint: '/global/rest/auth/logout/',
    method: 'post',
    content: 'json',
  },
  otp: {
    base: OCI,
    endpoint: '/global/rest/auth/otp/',
    method: 'post',
    content: 'json',
  },
  recover: {
    base: OCI,
    endpoint: '/global/rest/auth/recover/',
    method: 'post',
    content: 'json',
  },
  confirm: {
    base: OCI,
    endpoint: '/global/rest/auth/confirm/',
    method: 'post',
    content: 'json',
  },
  reset: {
    base: OCI,
    endpoint: '/global/rest/auth/reset/',
    method: 'post',
    content: 'json',
  },
  register: {
    base: OCI,
    endpoint: '/global/rest/auth/register/',
    method: 'post',
    content: 'json',
  },
  vortex: {
    login: {
      base: OCI,
      endpoint: '/global/rest/vortex/login/',
      method: 'post',
      content: 'json',
    },
    logout: {
      base: OCI,
      endpoint: '/global/rest/vortex/logout',
      method: 'post',
      content: 'json',
    },
  },
};

export const MISC = {
  jwt: {
    base: OCI,
    endpoint: '/global/rest/jwt/',
    method: 'post',
    content: 'json',
  }
};

export const TENANTS = {
  tenant: {
    create: {
      base: OCI,
      endpoint: '/global/rest/tenants/tenant/c',
      method: 'post',
      content: 'json',
    },
    check: {
      base: OCI,
      endpoint: '/global/rest/tenants/tenant/check',
      method: 'post',
      content: 'json',
    },
    read: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/r/id',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/r/codigo',
        method: 'post',
        content: 'json',
      },
      logo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/r/logo/',
        method: 'post',
        content: 'json',
      },
      timbrado: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/r/timbrado/',
        method: 'post',
        content: 'json',
      },
      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/r/all',
        method: 'post',
        content: 'json',
      },
      by_user: {
        base: OCI,
        endpoint: '/global/rest/tenants/r/by_user/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/codigo',
        method: 'post',
        content: 'json',
      },
      nome: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/nome',
        method: 'post',
        content: 'json',
      },
      email: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/email',
        method: 'post',
        content: 'json',
      },
      active: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/active',
        method: 'post',
        content: 'json',
      },
      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/all',
        method: 'post',
        content: 'json',
      },
      logo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/logo',
        method: 'post',
        content: 'files',
      },
      timbrado: {
        base: OCI,
        endpoint: '/global/rest/tenants/tenant/u/timbrado',
        method: 'post',
        content: 'files',
      },
    },
  },
  filiais: {
    create: {
      base: OCI,
      endpoint: '/global/rest/tenants/filiais/c',
      method: 'post',
      content: 'json',
    },
    read: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/r/id',
        method: 'post',
        content: 'json',
      },

      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/r/codigo',
        method: 'post',
        content: 'json',
      },

      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/r/all',
        method: 'post',
        content: 'json',
      },
    },

    update: {
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/codigo',
        method: 'post',
        content: 'json',
      },
      nome: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/nome',
        method: 'post',
        content: 'json',
      },
      email: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/email',
        method: 'post',
        content: 'json',
      },
      telefone: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/telefone',
        method: 'post',
        content: 'json',
      },
      active: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/active',
        method: 'post',
        content: 'json',
      },
      gestor_codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/gestor_codigo',
        method: 'post',
        content: 'json',
      },
      endereco: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/endereco',
        method: 'post',
        content: 'json',
      },
      cep: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/cep',
        method: 'post',
        content: 'json',
      },
      cidade: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/cidade',
        method: 'post',
        content: 'json',
      },
      uf: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/uf',
        method: 'post',
        content: 'json',
      },
      principal: {
        base: OCI,
        endpoint: '/global/rest/tenants/filiais/u/principal',
        method: 'post',
        content: 'json',
      },
    },
  },
  tokens: {
    create: {
      base: OCI,
      endpoint: '/global/rest/tenants/tokens/c',
      method: 'post',
      content: 'json',
    },
    read: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/r/id',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/r/codigo',
        method: 'post',
        content: 'json',
      },
      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/r/all',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/codigo',
        method: 'post',
        content: 'json',
      },
      nome: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/nome',
        method: 'post',
        content: 'json',
      },
      description: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/description',
        method: 'post',
        content: 'json',
      },
      access: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/access',
        method: 'post',
        content: 'json',
      },
      refresh: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/refresh',
        method: 'post',
        content: 'json',
      },
      expiration: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/u/expiration',
        method: 'post',
        content: 'json',
      },
    },
    delete: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/d/id',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/tokens/d/codigo',
        method: 'post',
        content: 'json',
      },
    },
  },
  users: {
    create: {
      base: OCI,
      endpoint: '/global/rest/tenants/users/c',
      method: 'post',
      content: 'json',
    },

    read: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/r/id',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/r/codigo',
        method: 'post',
        content: 'json',
      },
      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/r/all',
        method: 'post',
        content: 'json',
      },
      user: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/r/user/',
        method: 'post',
        content: 'json',
      },
    },
    update: {
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/u/codigo',
        method: 'post',
        content: 'json',
      },
      role: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/u/role',
        method: 'post',
        content: 'json',
      },
      filial: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/u/filial',
        method: 'post',
        content: 'json',
      },
      expiration: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/u/expiration',
        method: 'post',
        content: 'json',
      },
      active: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/u/active',
        method: 'post',
        content: 'json',
      },
    },
    delete: {
      id: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/d/id',
        method: 'post',
        content: 'json',
      },
      codigo: {
        base: OCI,
        endpoint: '/global/rest/tenants/users/d/codigo',
        method: 'post',
        content: 'json',
      },
    },
  },
  services: {
    read: {
      all: {
        base: OCI,
        endpoint: '/global/rest/tenants/services/r/all/',
        method: 'post',
        content: 'json',
      },
    },
    create: {
      base: OCI,
      endpoint: '/global/rest/tenants/services/c/',
      method: 'post',
      content: 'json',
    },
    link: {
      base: OAPI,
      endpoint: '/global/services/link/',
      method: 'post',
      content: 'json',
    },
    update: {
      base: OCI,
      endpoint: '/global/rest/tenants/services/u/token/',
      method: 'post',
      content: 'json',
    },
    delete: {
      base: OCI,
      endpoint: '/global/rest/tenants/services/d/',
      method: 'post',
      content: 'json',
    },
  },
};

export const SERVICES = {
  create: {
    base: OCI,
    endpoint: '/global/rest/services/c',
    method: 'post',
    content: 'json',
  },
  read: {
    codigo: {
      base: OCI,
      endpoint: '/global/rest/services/r/codigo',
      method: 'post',
      content: 'json',
    },
    all: {
      base: OCI,
      endpoint: '/global/rest/services/r/all/',
      method: 'post',
      content: 'json',
    },
  },
};

export const VIEWS = {
  planos_produtos: {
    base: OCI,
    endpoint: '/global/rest/views/planos_produtos/',
    method: 'post',
    content: 'json',
  },
  planos_produtos_sm: {
    base: OCI,
    endpoint: '/global/rest/views/planos_produtos_sm/',
    method: 'post',
    content: 'json',
  },
  subscriptions: {
    by_user: {
      base: OCI,
      endpoint: '/global/rest/views/subscriptions/by_user/',
      method: 'post',
      content: 'json',
    },
  },
  tenants_assinantes: {
    base: OCI,
    endpoint: '/global/rest/views/tenants_assinantes',
    method: 'post',
    content: 'json',
  },
  tenants_assinantes_users: {
    base: OCI,
    endpoint: '/global/rest/views/tenants_assinantes_users/',
    method: 'post',
    content: 'json',
  },
  pub: {
    planos_produtos: {
      base: OCI,
      endpoint: '/global/rest/views/pub/plans_products/',
      method: 'post',
      content: 'json',
    },
  }
};

export const MP = {
  checkout: {
    plans: {
      base: CAPI,
      endpoint: '/plans',
      method: 'post',
      content: 'json',
    },
    subscribe: {
      base: CAPI,
      endpoint: '/subscribe',
      method: 'post',
      content: 'json',
    },
    preference: {
      create: {
        base: CAPI,
        endpoint: '/preference/',
        method: 'post',
        content: 'json',
      },
    },
    process: {
      base: CAPI,
      endpoint: '/process/',
      method: 'post',
      content: 'json',
    },
  },
  core: {
    planos: {
      read: {
        all: {
          base: OAPI,
          endpoint: '/global/meli/plans/read/all/',
          method: 'get',
          content: 'json',
        },
      },
    },
  },
};

const END = {
  ip: {
    read: {
      base: 'https://api.ipify.org',
      endpoint: '/?format=json',
      method: 'get',
      content: 'json',
    },
  },
  apoio: {
    indices: {
      all: {
        base: OCI,
        endpoint: '/apoio/rest/indices/all',
        method: 'get',
        content: 'json',
      },
    },
    feriados: {
      all: {
        base: OCI,
        endpoint: '/apoio/rest/feriados/all',
        method: 'get',
        content: 'json',
      },
    },
  },
  checkout: {
    plans: {
      base: CAPI,
      endpoint: '/plans',
      method: 'post',
      content: 'json',
    },
    subscribe: {
      base: CAPI,
      endpoint: '/subscribe',
      method: 'post',
      content: 'json',
    },
    preference: {
      create: {
        base: CAPI,
        endpoint: '/preference/',
        method: 'post',
        content: 'json',
      },
    },
    process: {
      base: CAPI,
      endpoint: '/process/',
      method: 'post',
      content: 'json',
    },
  },
  global: {
    assinantes: {
      create: {
        base: OCI,
        endpoint: '/global/rest/assinantes/c/',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/assinantes/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/assinantes/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/assinantes/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        codigo: {
          base: OCI,
          endpoint: '/global/rest/assinantes/u/codigo',
          method: 'post',
          content: 'json',
        },
        nome: {
          base: OCI,
          endpoint: '/global/rest/assinantes/u/nome',
          method: 'post',
          content: 'json',
        },
        email: {
          base: OCI,
          endpoint: '/global/rest/assinantes/u/email',
          method: 'post',
          content: 'json',
        },
        active: {
          base: OCI,
          endpoint: '/global/rest/assinantes/u/active',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/assinantes/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/assinantes/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    auth: {
      login: {
        base: OCI,
        endpoint: '/global/rest/auth/login/',
        method: 'post',
        content: 'json',
      },
      logout: {
        base: OCI,
        endpoint: '/global/rest/auth/logout/',
        method: 'post',
        content: 'json',
      },
      recover: {
        base: OCI,
        endpoint: '/global/rest/auth/recover',
        method: 'post',
        content: 'json',
      },
      reset: {
        base: OCI,
        endpoint: '/global/rest/auth/reset',
        method: 'post',
        content: 'json',
      },
    },
    faturas: {
      create: {
        base: OCI,
        endpoint: '/global/rest/faturas/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/faturas/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/faturas/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/faturas/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        valor: {
          base: OCI,
          endpoint: '/global/rest/faturas/u/valor',
          method: 'post',
          content: 'json',
        },
        vencimento: {
          base: OCI,
          endpoint: '/global/rest/faturas/u/vencimento',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/faturas/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/faturas/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    frequencias: {
      create: {
        base: OCI,
        endpoint: '/global/rest/frequencias/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/frequencias/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/frequencias/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/frequencias/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        active: {
          base: OCI,
          endpoint: '/global/rest/frequencias/u/active',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/frequencias/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/frequencias/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    keepalive: {
      hello: {
        base: OCI,
        endpoint: '/global/rest/keepalive/hello',
        method: 'get',
        content: 'json',
      },
    },
    meli: {
      planos: {
        read: {
          all: {
            base: OAPI,
            endpoint: '/global/meli/plans/read/',
            method: 'get',
            content: 'json',
          },
        },
      },
    },
    pagamentos: {
      create: {
        base: OCI,
        endpoint: '/global/rest/pagamentos/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        valor: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/u/valor',
          method: 'post',
          content: 'json',
        },
        data: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/u/data',
          method: 'post',
          content: 'json',
        },
        meio: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/u/meio',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/pagamentos/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    planos: {
      create: {
        base: OCI,
        endpoint: '/global/rest/planos/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/planos/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/planos/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/planos/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        codigo: {
          base: OCI,
          endpoint: '/global/rest/planos/u/codigo',
          method: 'post',
          content: 'json',
        },
        nome: {
          base: OCI,
          endpoint: '/global/rest/planos/u/nome',
          method: 'post',
          content: 'json',
        },
        valor: {
          base: OCI,
          endpoint: '/global/rest/planos/u/valor',
          method: 'post',
          content: 'json',
        },
        active: {
          base: OCI,
          endpoint: '/global/rest/planos/u/active',
          method: 'post',
          content: 'json',
        },
        product_id: {
          base: OCI,
          endpoint: '/global/rest/planos/u/product_id',
          method: 'post',
          content: 'json',
        },
        frequencia_id: {
          base: OCI,
          endpoint: '/global/rest/planos/u/frequencia_id',
          method: 'post',
          content: 'json',
        },
        padrao: {
          base: OCI,
          endpoint: '/global/rest/planos/u/padrao',
          method: 'post',
          content: 'json',
        },
        trial: {
          base: OCI,
          endpoint: '/global/rest/planos/u/trial',
          method: 'post',
          content: 'json',
        },
        rota: {
          base: OCI,
          endpoint: '/global/rest/planos/u/rota',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/planos/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/planos/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    produtos: {
      create: {
        base: OCI,
        endpoint: '/global/rest/produtos/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/produtos/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/produtos/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/produtos/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        codigo: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/codigo',
          method: 'post',
          content: 'json',
        },
        nome: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/nome',
          method: 'post',
          content: 'json',
        },
        descricao: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/descricao',
          method: 'post',
          content: 'json',
        },
        global: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/global',
          method: 'post',
          content: 'json',
        },
        paywall: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/paywall',
          method: 'post',
          content: 'json',
        },
        service: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/service',
          method: 'post',
          content: 'json',
        },
        valor: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/valor',
          method: 'post',
          content: 'json',
        },
        active: {
          base: OCI,
          endpoint: '/global/rest/produtos/u/active',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/produtos/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/produtos/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    vortex: {
      login: {
        base: OCI,
        endpoint: '/global/rest/vortex/login/',
        method: 'post',
        content: 'json',
      },
      logout: {
        base: OCI,
        endpoint: '/global/rest/vortex/logout',
        method: 'post',
        content: 'json',
      },
    },
    roles: {
      create: {
        base: OCI,
        endpoint: '/global/rest/roles/c',
        method: 'post',
        content: 'json',
      },
      read: {
        id: {
          base: OCI,
          endpoint: '/global/rest/roles/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/roles/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/roles/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        codigo: {
          base: OCI,
          endpoint: '/global/rest/roles/u/codigo',
          method: 'post',
          content: 'json',
        },
        nome: {
          base: OCI,
          endpoint: '/global/rest/roles/u/nome',
          method: 'post',
          content: 'json',
        },
        email: {
          base: OCI,
          endpoint: '/global/rest/roles/u/email',
          method: 'post',
          content: 'json',
        },
        active: {
          base: OCI,
          endpoint: '/global/rest/roles/u/active',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/roles/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/roles/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    tenants: {
      tenant: {
        create: {
          base: OCI,
          endpoint: '/global/rest/tenants/tenant/c',
          method: 'post',
          content: 'json',
        },
        check: {
          base: OCI,
          endpoint: '/global/rest/tenants/tenant/check',
          method: 'post',
          content: 'json',
        },
        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/r/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/r/codigo',
            method: 'post',
            content: 'json',
          },
          logo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/r/logo',
            method: 'post',
            content: 'json',
          },
          timbrado: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/r/timbrado',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/r/all',
            method: 'post',
            content: 'json',
          },
        },
        update: {
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/codigo',
            method: 'post',
            content: 'json',
          },
          nome: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/nome',
            method: 'post',
            content: 'json',
          },
          email: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/email',
            method: 'post',
            content: 'json',
          },
          active: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/active',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/all',
            method: 'post',
            content: 'json',
          },
          logo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/logo',
            method: 'post',
            content: 'files',
          },
          timbrado: {
            base: OCI,
            endpoint: '/global/rest/tenants/tenant/u/timbrado',
            method: 'post',
            content: 'files',
          },
        },
      },
      filiais: {
        create: {
          base: OCI,
          endpoint: '/global/rest/tenants/filiais/c',
          method: 'post',
          content: 'json',
        },
        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/r/id',
            method: 'post',
            content: 'json',
          },

          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/r/codigo',
            method: 'post',
            content: 'json',
          },

          all: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/r/all',
            method: 'post',
            content: 'json',
          },
        },

        update: {
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/codigo',
            method: 'post',
            content: 'json',
          },
          nome: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/nome',
            method: 'post',
            content: 'json',
          },
          email: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/email',
            method: 'post',
            content: 'json',
          },
          telefone: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/telefone',
            method: 'post',
            content: 'json',
          },
          active: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/active',
            method: 'post',
            content: 'json',
          },
          gestor_codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/gestor_codigo',
            method: 'post',
            content: 'json',
          },
          endereco: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/endereco',
            method: 'post',
            content: 'json',
          },
          cep: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/cep',
            method: 'post',
            content: 'json',
          },
          cidade: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/cidade',
            method: 'post',
            content: 'json',
          },
          uf: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/uf',
            method: 'post',
            content: 'json',
          },
          principal: {
            base: OCI,
            endpoint: '/global/rest/tenants/filiais/u/principal',
            method: 'post',
            content: 'json',
          },
        },
      },
      tokens: {
        create: {
          base: OCI,
          endpoint: '/global/rest/tenants/tokens/c',
          method: 'post',
          content: 'json',
        },
        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/r/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/r/codigo',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/r/all',
            method: 'post',
            content: 'json',
          },
        },
        update: {
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/codigo',
            method: 'post',
            content: 'json',
          },
          nome: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/nome',
            method: 'post',
            content: 'json',
          },
          description: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/description',
            method: 'post',
            content: 'json',
          },
          access: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/access',
            method: 'post',
            content: 'json',
          },
          refresh: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/refresh',
            method: 'post',
            content: 'json',
          },
          expiration: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/u/expiration',
            method: 'post',
            content: 'json',
          },
        },
        delete: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/d/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/tokens/d/codigo',
            method: 'post',
            content: 'json',
          },
        },
      },
      users: {
        create: {
          base: OCI,
          endpoint: '/global/rest/tenants/users/c',
          method: 'post',
          content: 'json',
        },

        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/r/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/r/codigo',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/r/all',
            method: 'post',
            content: 'json',
          },
          user: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/r/user/',
            method: 'post',
            content: 'json',
          },
        },
        update: {
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/u/codigo',
            method: 'post',
            content: 'json',
          },
          role: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/u/role',
            method: 'post',
            content: 'json',
          },
          filial: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/u/filial',
            method: 'post',
            content: 'json',
          },
          expiration: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/u/expiration',
            method: 'post',
            content: 'json',
          },
          active: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/u/active',
            method: 'post',
            content: 'json',
          },
        },
        delete: {
          id: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/d/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/tenants/users/d/codigo',
            method: 'post',
            content: 'json',
          },
        },
      },
    },
    support: {
      messages: {
        create: {
          base: OCI,
          endpoint: '/global/rest/support_messages/c',
          method: 'post',
          content: 'json',
        },
        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/support_messages/r/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/support_messages/r/codigo',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/support_messages/r/all',
            method: 'post',
            content: 'json',
          },

          ticket: {
            base: OCI,
            endpoint: '/global/rest/support_messages/r/ticket',
            method: 'post',
            content: 'json',
          },
        },

        delete: {
          id: {
            base: OCI,
            endpoint: '/global/rest/support_messages/d/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/support_messages/d/codigo',
            method: 'post',
            content: 'json',
          },
        },
      },
      tickets: {
        create: {
          base: OCI,
          endpoint: '/global/rest/support_tickets/c',
          method: 'post',
          content: 'json',
        },
        read: {
          id: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/r/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/r/codigo',
            method: 'post',
            content: 'json',
          },
          all: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/r/all',
            method: 'post',
            content: 'json',
          },
        },
        update: {
          codigo: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/u/codigo',
            method: 'post',
            content: 'json',
          },
        },
        delete: {
          id: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/d/id',
            method: 'post',
            content: 'json',
          },
          codigo: {
            base: OCI,
            endpoint: '/global/rest/support_tickets/d/codigo',
            method: 'post',
            content: 'json',
          },
        },
      },
    },
    users: {
      create: {
        base: OCI,
        endpoint: '/global/rest/users/c',
        method: 'post',
        content: 'json',
      },
      read: {
        email: {
          base: OCI,
          endpoint: '/global/rest/users/r/email',
          method: 'post',
          content: 'json',
        },
        cpf: {
          base: OCI,
          endpoint: '/global/rest/users/r/cpf',
          method: 'post',
          content: 'json',
        },
        id: {
          base: OCI,
          endpoint: '/global/rest/users/r/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/users/r/codigo',
          method: 'post',
          content: 'json',
        },
        all: {
          base: OCI,
          endpoint: '/global/rest/users/r/all',
          method: 'post',
          content: 'json',
        },
      },
      update: {
        email: {
          base: OCI,
          endpoint: '/global/rest/users/u/email',
          method: 'post',
          content: 'json',
        },
        cpf: {
          base: OCI,
          endpoint: '/global/rest/users/u/cpf',
          method: 'post',
          content: 'json',
        },
        telefone: {
          base: OCI,
          endpoint: '/global/rest/users/u/telefone',
          method: 'post',
          content: 'json',
        },
        password: {
          base: OCI,
          endpoint: '/global/rest/users/u/password',
          method: 'post',
          content: 'json',
        },
        active: {
          base: OCI,
          endpoint: '/global/rest/users/u/active',
          method: 'post',
          content: 'json',
        },
        cep: {
          base: OCI,
          endpoint: '/global/rest/users/u/cep/',
          method: 'post',
          content: 'json',
        },
        endnum: {
          base: OCI,
          endpoint: '/global/rest/users/u/endnum/',
          method: 'post',
          content: 'json',
        },
        end: {
          base: OCI,
          endpoint: '/global/rest/users/u/end/',
          method: 'post',
          content: 'json',
        },
      },
      delete: {
        id: {
          base: OCI,
          endpoint: '/global/rest/users/d/id',
          method: 'post',
          content: 'json',
        },
        codigo: {
          base: OCI,
          endpoint: '/global/rest/users/d/codigo',
          method: 'post',
          content: 'json',
        },
      },
    },
    uuid: {
      new: {
        base: OCI,
        endpoint: '/global/rest/uuid/new',
        method: 'get',
        content: 'json',
      },
    },
    vba: {
      login: {
        juris: {
          base: OCI,
          endpoint: '/global/rest/vba/login/juris',
          method: 'post',
          content: 'json',
        },
      },
    },
    views: {
      planos_produtos: {
        base: OCI,
        endpoint: '/global/rest/views/planos_produtos/',
        method: 'post',
        content: 'json',
      },
      planos_produtos_sm: {
        base: OCI,
        endpoint: '/global/rest/views/planos_produtos_sm/',
        method: 'post',
        content: 'json',
      },
      tenants_assinantes: {
        base: OCI,
        endpoint: '/global/rest/views/tenants_assinantes',
        method: 'post',
        content: 'json',
      },
      tenants_assinantes_users: {
        base: OCI,
        endpoint: '/global/rest/views/tenants_assinantes_users/',
        method: 'post',
        content: 'json',
      },
    },
  },
};

export default END;

export const schemas = {
  global: `/global`,
  apoio: `/apoio`,
  juris: `/juris`,
};

// export const ip = {
//   read: `${IAPI}`,
// };

export const apoio = {
  indices: {
    all: `${schemas.apoio}/indices/all`,
  },
  feriados: {
    all: `${schemas.apoio}/feriados/all`,
  },
};

export const global = {
  auth: `${schemas.global}/auth`,
  vortex: `${schemas.global}/vortex`,
  roles: `${schemas.global}/roles`,
  tenants: {
    tenant: `${schemas.global}/tenants/tenant`,
    filiais: `${schemas.global}/tenants/filiais`,
    users: `${schemas.global}/tenants/users`,
  },
  users: `${schemas.global}/users`,
  planos: `${schemas.global}/planos`,
  assinantes: `${schemas.global}/assinantes`,
  faturas: `${schemas.global}/faturas`,
  frequencias: `${schemas.global}/frequencias`,
  produtos: `${schemas.global}/produtos`,
  pagamentos: `${schemas.global}/pagamentos`,
  support: {
    tickets: `${schemas.global}/support_tickets`,
    messages: `${schemas.global}/support_messages`,
  },
  views: `${schemas.global}/views`,
};

export const roles = {
  create: `${global.roles}/c`,
  read: {
    id: `${global.roles}/r/id`,
    codigo: `${global.roles}/r/codigo`,
    all: `${global.roles}/r/all`,
  },
  update: {
    codigo: `${global.roles}/u/codigo`,
    nome: `${global.roles}/u/nome`,
    email: `${global.roles}/u/email`,
    active: `${global.roles}/u/active`,
  },
  delete: {
    id: `${global.roles}/d/id`,
    codigo: `${global.roles}/d/codigo`,
  },
};

export const tenants = {
  tenant: {
    create: `${global.tenants.tenant}/c`,
    check: `${global.tenants.tenant}/check`,
    read: {
      id: `${global.tenants.tenant}/r/id`,
      codigo: `${global.tenants.tenant}/r/codigo`,
      logo: `${global.tenants.tenant}/r/logo`,
      timbrado: `${global.tenants.tenant}/r/timbrado`,
      all: `${global.tenants.tenant}/r/all`,
    },
    update: {
      codigo: `${global.tenants.tenant}/u/codigo`,
      nome: `${global.tenants.tenant}/u/nome`,
      email: `${global.tenants.tenant}/u/email`,
      active: `${global.tenants.tenant}/u/active`,
      all: `${global.tenants.tenant}/u/all`,
      logo: `${global.tenants.tenant}/u/logo`,
      timbrado: `${global.tenants.tenant}/u/timbrado`,
    },
    delete: {
      id: `${global.tenants.tenant}/d/id`,
      codigo: `${global.tenants.tenant}/d/codigo`,
    },
  },
  filiais: {
    create: `${global.tenants.filiais}/c`,
    read: {
      id: `${global.tenants.filiais}/r/id`,
      codigo: `${global.tenants.filiais}/r/codigo`,
      all: `${global.tenants.filiais}/r/all`,
    },
    update: {
      codigo: `${global.tenants.filiais}/u/codigo`,
      nome: `${global.tenants.filiais}/u/nome`,
      email: `${global.tenants.filiais}/u/email`,
      active: `${global.tenants.filiais}/u/active`,
    },
    delete: {
      id: `${global.tenants.filiais}/d/id`,
      codigo: `${global.tenants.filiais}/d/codigo`,
    },
  },
  users: {
    create: `${global.tenants.users}/c`,
    read: {
      id: `${global.tenants.users}/r/id`,
      codigo: `${global.tenants.users}/r/codigo`,
      all: `${global.tenants.users}/r/all`,
      user: `${global.tenants.users}/r/user`,
    },
    update: {
      codigo: `${global.tenants.users}/u/codigo`,
      nome: `${global.tenants.users}/u/nome`,
      email: `${global.tenants.users}/u/email`,
      active: `${global.tenants.users}/u/active`,
    },
    delete: {
      id: `${global.tenants.users}/d/id`,
      codigo: `${global.tenants.users}/d/codigo`,
    },
  },
};

export const auth = {
  login: `${global.auth}/login`,
  logout: `${global.auth}/logout`,
  recover: `${global.auth}/recover`,
  reset: `${global.auth}/reset`,
};

export const vortex = {
  login: `${global.vortex}/login`,
  logout: `${global.vortex}/logout`,
};

// const global = {
//   users: '/users',
//   auth: '/auth',
//   tenants: {
//     tenant: '/tenants',
//     filiais: '/tenantsfiliais',
//     users: '/tenantsusers',
//   },
//   planos: '/planos',
//   assinantes: '/assinantes',
//   faturas: '/faturas',
//   frequencias: '/frequencias',
//   produtos: '/produtos',
//   pagamentos: '/pagamentos',
//   roles: '/roles',
//   support: {
//     tickets: '/support_tickets',
//     messages: '/support_messages'
//   },
//   views: '/views',
// }

export const users = {
  create: `${global.users}/c`,
  read: {
    email: `${global.users}/r/email`,
    cpf: `${global.users}/r/cpf`,
    id: `${global.users}/r/id`,
    codigo: `${global.users}/r/codigo`,
    all: `${global.users}/r/all`,
  },
  update: {
    email: `${global.users}/u/email`,
    cpf: `${global.users}/u/cpf`,
    telefone: `${global.users}/u/telefone`,
    password: `${global.users}/u/password`,
    active: `${global.users}/u/active`,
  },

  delete: {
    id: `${global.users}/d/id`,
    codigo: `${global.users}/d/codigo`,
  },
};

export const assinantes = {
  create: `${global.assinantes}/c`,
  read: {
    id: `${global.assinantes}/r/id`,
    codigo: `${global.assinantes}/r/codigo`,
    all: `${global.assinantes}/r/all`,
    tenant: `${global.assinantes}/r/tenant/all`,
  },
  update: {
    codigo: `${global.assinantes}/u/codigo`,
    nome: `${global.assinantes}/u/nome`,
    email: `${global.assinantes}/u/email`,
    active: `${global.assinantes}/u/active`,
  },
  delete: {
    id: `${global.assinantes}/d/id`,
    codigo: `${global.assinantes}/d/codigo`,
  },
};

export const planos = {
  create: `${global.planos}/c`,
  read: {
    id: `${global.planos}/r/id`,
    codigo: `${global.planos}/r/codigo`,
    all: `${global.planos}/r/all`,
  },
  update: {
    codigo: `${global.planos}/u/codigo`,
    nome: `${global.planos}/u/nome`,
    email: `${global.planos}/u/email`,
    active: `${global.planos}/u/active`,
  },
  delete: {
    id: `${global.planos}/d/id`,
    codigo: `${global.planos}/d/codigo`,
  },
};

export const faturas = {
  create: `${global.faturas}/c`,
  read: {
    id: `${global.faturas}/r/id`,
    codigo: `${global.faturas}/r/codigo`,
    all: `${global.faturas}/r/all`,
  },
  update: {
    codigo: `${global.faturas}/u/codigo`,
    nome: `${global.faturas}/u/nome`,
    email: `${global.faturas}/u/email`,
    active: `${global.faturas}/u/active`,
  },
  delete: {
    id: `${global.faturas}/d/id`,
    codigo: `${global.faturas}/d/codigo`,
  },
};

export const frequencias = {
  create: `${global.frequencias}/c`,
  read: {
    id: `${global.frequencias}/r/id`,
    codigo: `${global.frequencias}/r/codigo`,
    all: `${global.frequencias}/r/all`,
  },
  update: {
    codigo: `${global.frequencias}/u/codigo`,
    nome: `${global.frequencias}/u/nome`,
    email: `${global.frequencias}/u/email`,
    active: `${global.frequencias}/u/active`,
  },
  delete: {
    id: `${global.frequencias}/d/id`,
    codigo: `${global.frequencias}/d/codigo`,
  },
};

export const produtos = {
  create: `${global.produtos}/c`,
  read: {
    id: `${global.produtos}/r/id`,
    codigo: `${global.produtos}/r/codigo`,
    all: `${global.produtos}/r/all`,
  },
  update: {
    codigo: `${global.produtos}/u/codigo`,
    nome: `${global.produtos}/u/nome`,
    email: `${global.produtos}/u/email`,
    active: `${global.produtos}/u/active`,
  },
  delete: {
    id: `${global.produtos}/d/id`,
    codigo: `${global.produtos}/d/codigo`,
  },
};

export const pagamentos = {
  create: `${global.pagamentos}/c`,
  read: {
    id: `${global.pagamentos}/r/id`,
    codigo: `${global.pagamentos}/r/codigo`,
    all: `${global.pagamentos}/r/all`,
  },
  update: {
    codigo: `${global.pagamentos}/u/codigo`,
    nome: `${global.pagamentos}/u/nome`,
    email: `${global.pagamentos}/u/email`,
    active: `${global.pagamentos}/u/active`,
  },
  delete: {
    id: `${global.pagamentos}/d/id`,
    codigo: `${global.pagamentos}/d/codigo`,
  },
};

export const support = {
  tickets: {
    create: `${global.support.tickets}/c`,
    read: {
      id: `${global.support.tickets}/r/id`,
      codigo: `${global.support.tickets}/r/codigo`,
      all: `${global.support.tickets}/r/all`,
    },
    update: {
      codigo: `${global.support.tickets}/u/codigo`,
      nome: `${global.support.tickets}/u/nome`,
      email: `${global.support.tickets}/u/email`,
      active: `${global.support.tickets}/u/active`,
    },
    delete: {
      id: `${global.support.tickets}/d/id`,
      codigo: `${global.support.tickets}/d/codigo`,
    },
  },
  messages: {
    create: `${global.support.messages}/c`,
    read: {
      id: `${global.support.messages}/r/id`,
      codigo: `${global.support.messages}/r/codigo`,
      all: `${global.support.messages}/r/all`,
    },
    update: {
      codigo: `${global.support.messages}/u/codigo`,
      nome: `${global.support.messages}/u/nome`,
      email: `${global.support.messages}/u/email`,
      active: `${global.support.messages}/u/active`,
    },
    delete: {
      id: `${global.support.messages}/d/id`,
      codigo: `${global.support.messages}/d/codigo`,
    },
  },
};

export const views = {
  planos_produtos: `${global.views}/planos_produtos`,
  planos_produtos_sm: `${global.views}/planos_produtos_sm`,
  tenants_users: `${global.views}/tenants_users`,
  tenants_assinantes: `${global.views}/tenants_assinantes`,
  tenants_assinantes_users: `${global.views}/tenants_assinantes_users`,
};
