import ContainerBox from '../../packages/wookie/box/ContainerBox';
import NavBar from '../../components/NavBar';

export default function Termos() {
  return (
    <ContainerBox
      navbar={<NavBar />}
      navreturn={true}
      main={
        <div className='container'>
          <h4 className='ffSoft mb-5'>Termos de Uso e Condições</h4>
          <p>
            <strong>Última Atualização: 01/01/2025</strong>
          </p>

          <h5 className='textContrastColor ffSoft my-5'>GLOSSÁRIO</h5>

          <p>
            BEWOOK: é o site disponível na internet, pelo endereço
            www.bewook.com e seus subdomínios, por qualquer endereço em
            *.bewook.com, que disponibiliza a tecnologia objeto deste contrato
            de SaaS (Software as a Service).
          </p>

          <p>
            USUÁRIO: é a pessoa física que adere a este termo de forma expressa
            ao se cadastrar e selecionar a caixa “Concordo com os Termos e
            Condições”, após proceder com sua leitura de forma cuidadosa,
            juntamente dos demais termos e políticas disponíveis na plataforma,
            realizando por fim seu cadastro na BEWOOK, caso concorde com todas
            as políticas e regras.
          </p>

          <p>
            SaaS: é a sigla para Software as a Service, que significa Software
            como Serviço, e é a modalidade de contratação de software em que o
            USUÁRIO não precisa adquirir a licença de uso do software, mas sim
            pagar pelo seu uso, como se fosse um serviço.
          </p>

          <p>
            SERVIÇOS: são os serviços disponibilizados pela BEWOOK, que incluem
            o acesso ao SaaS, a utilização de suas funcionalidades, a integração
            com outras plataformas, a disponibilização de API, o suporte
            técnico, entre outros.
          </p>

          <p>
            SOFTWARE: é o programa de computador disponibilizado pela BEWOOK,
            que é acessado pelo USUÁRIO por meio da internet, e que é objeto
            deste termo de SaaS.
          </p>

          <h5 className='textContrastColor ffSoft my-5'>CONDIÇÕES DE USO</h5>

          <p>
            1. O USUÁRIO deverá utilizar o SaaS de acordo com a legislação
            vigente e os direitos autorais.
          </p>

          <p>
            2. O USUÁRIO deverá manter a confidencialidade de suas credenciais
            de acesso ao SaaS, bem como manter seus dados atualizados na
            plataforma da BEWOOK. A BEWOOK poderá, a qualquer tempo, solicitar
            documentos que comprovem a legitimidade dos dados de cadastro
            fornecidos pelo usuário (nome e cpf), para assegurar a autenticidade
            e veracidade das informações, podendo a conta ser suspensa até a
            regularização.
          </p>

          <p>
            3. A BEWOOK disponibiliza o SaaS ao USUÁRIO, mediante o pagamento de
            uma mensalidade, que é calculada de acordo com o plano escolhido
            pelo USUÁRIO, e que é paga por meio de cartão de crédito. Caso o
            plano escolhido seja gratuito, o USUÁRIO não precisará pagar
            mensalidade. A BEWOOK se reserva o direito de alterar os planos e
            valroes, respeitando o valor contratado pelo usuário pelo prazo
            estabelecido.
          </p>

          <p>
            4. O USUÁRIO poderá utilizar os SaaS disponibilizados pela BEWOOK da
            forma que lhe convier, sendo o único responsável pelos dados
            inseridos na plataforma, caso sejam de terceiros, e pela obtenção de
            autorização para tratamento de tais dados, bem como pela manutenção
            dos acessos e senhas de forma segura.
          </p>

          <p>
            5. A BEWOOK disponibiliza suporte técnico ao USUÁRIO, por e-mail e
            pelo sistema de tickets disponível na plataforma.
          </p>

          <p>
            6. O USUÁRIO poderá cancelar sua assinatura a qualquer momento, sem
            multa, por meio da plataforma, sendo devido o valor proporcional ao
            período utilizado.
          </p>

          <p>
            7. A BEWOOK poderá encerrar a assinatura do USUÁRIO, caso este
            descumpra os termos aqui indicados, sem prejuízo de outras medidas
            cabíveis.
          </p>

          <p>
            8. A BEWOOK poderá alterar os termos deste documento a qualquer
            momento, sendo o USUÁRIO responsável por verificar as alterações
            diretamente na plataforma.
          </p>

          <p>
            9. O USUÁRIO poderá solicitar a exclusão de seus dados da plataforma
            a qualquer momento, por meio da plataforma, ou pelo e-mail{' '}
            <a
              aria-label='mailto'
              href='mailto:lgpd@bewook.com'
            >
              lgpd@bewook.com
            </a>
            . Confira a Política de Privacidade da Bewook{' '}
            <a
              aria-label='Política de Privacidade'
              href='/privacidade'
            >
              aqui
            </a>
            .
          </p>

          <p>
            10. Este termo é regido pela legislação brasileira, e qualquer
            controvérsia será resolvida no foro da comarca de Taubaté-SP.
          </p>
        </div>
      }
    />
  );
}
