import React, { useContext, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import { GlobalContext } from '../../context/Context';
import ContainerBox from '../../packages/wookie/box/ContainerBox';
import PackageContext from '../../packages/wookie/context/PackageContext';

import '../../assets/css/app/pad.css';
import '../../assets/css/home.css';
import CONF from '../../config';
import { IC } from '../../packages/wookie/icons';
import PadProvider from '../../context/PadProvider';
import SideBar from '../../components/erp/SideBar';
import RenderSection from '../../components/erp/RenderSection';

export default function Pad() {
  const { theme } = useContext(PackageContext);
  const { setProduto, checkLogin, navigate, tenant } =
    useContext(GlobalContext);

  const logged = checkLogin();

  useEffect(() => {
    setProduto('pad');
  }, [setProduto]);

  const features = {
    agenda: {
      name: 'Agenda',
      description:
        'Serviço de agendamento de compromissos e reuniões, com integração ao Google Calendar',
      measure: 'novos eventos cadastrados por mês',
    },
    agenda_ext: {
      name: 'Agenda Online',
      description:
        'Agenda online para clientes e fornecedores lhe enviarem diretamente a solicitação de agendamento, de acordo com a sua disponibilidade informada no app, com integração ao Google Calendar',
      measure: 'novos eventos cadastrados por mês',
    },
    tarefas: {
      name: 'Tarefas',
      description: 'Organização de tarefas e prazos de forma eficaz',
      measure: 'novas tarefas cadastradas por mês',
    },
    calculo: {
      name: 'Cálculos',
      description:
        'Módulo de cálculos de atualizações monetárias, juros, multas e correções, com integração ao Google Sheets',
      measure: 'novos cálculos realizados por mês',
    },
    cadastro: {
      name: 'Cadastros',
      description: 'Cadastro de clientes, fornecedores e colaboradores',
      measure: 'novos cadastros realizados por mês',
    },
    contrato: {
      name: 'Contratos',
      description: 'Gestão de contratos, com alertas de vencimento e renovação',
      measure: 'novos contratos cadastrados por mês',
    },
    flow: {
      name: 'Fluxos de Trabalho',
      description: 'Gestão de processos e fluxos de trabalho',
      measure: 'novos processos cadastrados por mês',
    },
    financeiro: {
      name: 'Gestão Financeira',
      description:
        'Gestão financeira, contas a pagar e a receber, com integração ao Google Sheets',
      measure: 'novos lançamentos realizados por mês',
    },
    armazenamento: {
      name: 'Retenção de Dados',
      description:
        'Período de armazenamento dos dados cadastrados, para reemitir relatórios e documentos',
      measure: 'tempo de armazenamento',
    },
    signer: {
      name: 'Assinador Digital',
      description: 'Assinador digital para documentos e contratos',
      measure: 'número de documentos assinados por mês',
    },
  };

  const versions = {
    free: {
      agenda: 5,
      agenda_ext: 1,
      tarefas: 10,
      calculo: 1,
      cadastro: 2,
      contrato: 2,
      flow: 2,
      financeiro: 5,
      armazenamento: '7 dias',
      signer: 0,
      price: 0,
    },
    basic: {
      agenda: 20,
      agenda_ext: 5,
      tarefas: 50,
      calculo: 10,
      cadastro: 15,
      contrato: 10,
      flow: 10,
      financeiro: 50,
      armazenamento: '60 dias',
      signer: 1,
      price: 49,
    },
    pro: {
      agenda: 50,
      agenda_ext: 15,
      tarefas: 75,
      calculo: 25,
      cadastro: 50,
      contrato: 50,
      flow: 50,
      financeiro: 250,
      armazenamento: '365 dias',
      signer: 3,
      price: 79,
    },
    premium: {
      agenda: 200,
      agenda_ext: 50,
      tarefas: 250,
      calculo: 50,
      cadastro: 250,
      contrato: 250,
      flow: 250,
      financeiro: 250,
      armazenamento: '730 dias',
      signer: 5,
      price: 139,
    },
    master: {
      agenda: 500,
      agenda_ext: 100,
      tarefas: 500,
      calculo: 100,
      cadastro: 500,
      contrato: 500,
      flow: 500,
      financeiro: 500,
      armazenamento: '730 dias',
      signer: 10,
      price: 199,
    }
  };

  // const extra = {
  //   agenda_ext: {
  //     basic: {
  //       quant: 50,
  //       ext: 15,
  //       price: 19,
  //     },
  //     pro: {
  //       quant: 75,
  //       ext: 25,
  //       price: 29,
  //     },
  //     premium: {
  //       quant: 200,
  //       ext: 75,
  //       price: 49,
  //     },
  //   },
  //   calculo: {
  //     basic: {
  //       quant: 10,
  //       price: 9,
  //     },
  //     pro: {
  //       quant: 25,
  //       price: 19,
  //     },
  //     premium: {
  //       quant: 100,
  //       price: 49,
  //     },
  //   },
  //   signer: {
  //     basic: {
  //       quant: 5,
  //       price: 19,
  //     },
  //     pro: {
  //       quant: 10,
  //       price: 29,
  //     },
  //     premium: {
  //       quant: 25,
  //       price: 49,
  //     },
  //     master: {
  //       quant: 70,
  //       price: 99,
  //     }
  //   },
  // };

  const landing = () => {
    return (
      <ContainerBox
        main={
          <div className=' bgOverlayDarker m-5'>
            <div className='p-5'>
              <div className='d-flex flex-column justify-content-start p-3'>
                <img
                  src={CONF.images[theme].pad}
                  alt='bewook pad'
                  aria-label='bewook pad'
                  className='w80 wpMax300'
                />
                <p className='ffSoft'>Sua empresa na palma da sua mão</p>
              </div>

              <div className='d-flex flex-column justify-content-start my-3'>
                <section className='hero my-5 p-5 wMax90 mAuto'>
                  <h1>Facilite sua Gestão, Digitalize seu Escritório</h1>
                  <p>
                    Com a Plataforma Avançada Digital, você gerencia documentos,
                    equipe e tarefas de qualquer lugar. Toda a gestão
                    financeira, de contratos, clientes e fornecedores em um só
                    lugar.
                  </p>
                  <button
                    className='cta-button ffSoft'
                    onClick={() => navigate('/login')}
                  >
                    Comece Agora
                  </button>
                </section>

                <section className='features p-3'>
                  <div className='feature'>
                    <IC.FaRegFile className='svg26 mb-3' />
                    <h3>Gestão de Documentos</h3>
                    <p>
                      Organize, compartilhe e acesse todos os seus documentos em
                      um só lugar, integrando ao Google Drive
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.LiaUserFriendsSolid className='svg26 mb-3' />

                    <h3>Colaboração em Equipe</h3>
                    <p>
                      Facilite o trabalho em equipe com ferramentas de
                      colaboração eficazes.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.FaRegCalendarAlt className='svg26 mb-3' />

                    <h3>Agendamentos Simples</h3>
                    <p>Gerencie compromissos e reuniões com praticidade.</p>
                  </div>
                </section>

                <section className='features'>
                  <div className='feature'>
                    <IC.PiCurrencyCircleDollarFill className='svg26 mb-3' />
                    <h3>Gestão Financeira</h3>
                    <p>
                      Controle suas finanças, contas a pagar e a receber, de
                      forma simples e eficiente.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.GoTasklist className='svg26 mb-3' />

                    <h3>Organização de tarefas e prazos</h3>
                    <p>
                      Organize tarefas e prazos de forma eficaz, evitando
                      esquecimentos.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.FaRegStar className='svg26 mb-3' />

                    <h3>E muito mais</h3>
                    <p>
                      Integre com outros módulos bewook, e eleve sua
                      produtividade a um novo patamar
                    </p>
                  </div>
                </section>

                <section
                  className='hero my-3 gocol '
                  data-bs-theme={theme}
                >
                  <h3>Escolha o Plano Ideal para você</h3>
                  <h6>Consulte os limites de novos eventos por mês</h6>

                  <div className='gorow flex-wrap justify-content-center fs09 pricing'>
                    {Object.keys(versions).map((v) => (
                      <div
                        key={v}
                        className='card mx-3 my-5 p-3 box w15 wpMin175'
                      >
                        <div className='card-body'>
                          <h5 className='card-title textContrastColor ffSoft'>
                            {v}
                          </h5>
                          <p
                            className='card-text gocol py-1 border-bottom border-secondary'
                            title={features.agenda.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.agenda.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.agenda.measure}
                            >
                              {versions[v].agenda}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.agenda_ext.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.agenda_ext.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.agenda_ext.measure}
                            >
                              {versions[v].agenda_ext}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.tarefas.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.tarefas.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.tarefas.measure}
                            >
                              {versions[v].tarefas}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.calculo.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.calculo.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.calculo.measure}
                            >
                              {versions[v].calculo}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.cadastro.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.cadastro.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.cadastro.measure}
                            >
                              {versions[v].cadastro}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.contrato.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.contrato.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.contrato.measure}
                            >
                              {versions[v].contrato}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.flow.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.flow.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.flow.measure}
                            >
                              {versions[v].flow}
                            </span>
                          </p>

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.financeiro.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.financeiro.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.financeiro.measure}
                            >
                              {versions[v].financeiro}
                            </span>
                          </p>

                          {/* <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.armazenamento.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.armazenamento.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.armazenamento.measure}
                            >
                              {versions[v].armazenamento}
                            </span>
                          </p> */}

                          <p
                            className='card-text gocol py-2 border-bottom border-secondary'
                            title={features.signer.description}
                          >
                            <span className='ffSoft textContrastLow mousePointerHover'>
                              {features.signer.name}
                            </span>
                            <span
                              className='fs28 textContrast hovYellow mousePointerHover'
                              title={features.signer.measure}
                            >
                              {versions[v].signer}
                            </span>
                          </p>

                          <div className='price py-3'>
                            <sup>R$</sup>
                            {versions[v].price}
                            <span> / mês</span>
                          </div>

                          <div className='d-flex justify-content-center'>
                            <button
                              className='cta-button ffSoft'
                              onClick={() => navigate('/login')}
                            >
                              Escolher
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h3 className='pt-5'>
                    Precisa de um limite maior de recursos?
                  </h3>
                  <h6>
                    Consulte os pacotes avulsos para alavancar suas atividades
                  </h6>

                </section>

                <section className='hero my-3 wMax90 mAuto'>
                  <h3>Teste Grátis por 7 dias</h3>
                  <p className='px-3 mb-0'>
                    Experimente todas as funcionalidades do bewook Pad por 7
                    dias, sem compromisso.
                  </p>
                  <p className='fs10 mt-0'>
                    Algumas funcionalidades extras, como os cálculos ou o
                    assinador digital podem não estar disponíveis no período
                    gratuito
                  </p>
                  <button
                    className='cta-button ffSoft'
                    onClick={() => navigate('/login')}
                  >
                    Comece Agora
                  </button>
                </section>
              </div>
            </div>
          </div>
        }
        classMain='bgPad'
      />
    );
  };

  if (!logged) return landing();

  if (!tenant)
    return (
      <PadProvider>
        <ContainerBox
          classFull={`pad_${theme} vhMin100`}
          navbar={
            <NavBar
              navreturn
              unsetBg
              showLogo
              className='bgTransparent'
              tenantMenu
            />
          }
          main={
            <div className='p-2 d-flex flex-column justify-content-center'>
              <h5 className='faCenter'>
                Selecione uma conta no menu superior para continuar
              </h5>
            </div>
          }
          classMain='container'
        />
      </PadProvider>
    );

  return (
    <PadProvider>
      <ContainerBox
        classFull={`pad_${theme} vhMin100`}
        sidebar={<SideBar />}
        navbar={
          <NavBar
            navreturn
            unsetBg
            tenantMenu
            showLogo
            className='bgTransparent'
          />
        }
        main={
          <div className='p-2 d-flex flex-column justify-content-start'>
            <RenderSection />
          </div>
        }
        classMain='container'
      />
    </PadProvider>
  );
}
