import ContainerBox from '../../packages/wookie/box/ContainerBox';
import NavBar from '../../components/NavBar';

export default function Privacidade() {
  return (
    <ContainerBox
      navbar={<NavBar />}
      navreturn={true}
      main={
        <div className='container'>
          <h4 className='ffSoft mb-5'>Política de Privacidade </h4>
          <p>
            <strong>Última Atualização: 01/01/2025</strong>
          </p>

          <p>
            Bem-vindo à política de privacidade da Bewook! A proteção de seus
            dados é uma prioridade para nós. Este documento explica como
            coletamos, usamos, armazenamos e compartilhamos as informações
            fornecidas por você.
          </p>

          <h5 className='textContrastColor ffSoft'>1. Dados Coletados</h5>

          <h6>1.1. Durante o Cadastro</h6>
          <p>
            Solicitamos os seguintes dados pessoais para criar sua conta no site{' '}
            <strong>bewook.com</strong>:
          </p>
          <ul>
            <li>Nome completo;</li>
            <li>CPF;</li>
            <li>WhatsApp;</li>
            <li>Email.</li>
          </ul>

          <h6>1.2. Dados de Clientes</h6>
          <p>
            No uso do SaaS, você pode cadastrar dados de seus clientes. As
            informações inseridas são de sua responsabilidade e serão tratadas
            conforme esta política.
          </p>

          <h6>1.3. Pagamento</h6>
          <p>
            Caso contrate nossos serviços, os pagamentos serão realizados por
            cartão de crédito. Utilizamos provedores terceirizados para o
            processamento dos pagamentos. Esses provedores são responsáveis pela
            segurança dos dados financeiros.
          </p>

          <h5 className='textContrastColor ffSoft'>2. Uso das Informações</h5>
          <p>As informações coletadas são utilizadas para:</p>
          <ol>
            <li>Criar e gerenciar sua conta;</li>
            <li>Permitir o funcionamento adequado do SaaS;</li>
            <li>Entrar em contato para suporte ou informações importantes;</li>
            <li>Processar pagamentos e emissão de notas fiscais;</li>
            <li>Melhorar nossos serviços.</li>
          </ol>

          <h5 className='textContrastColor ffSoft'>
            3. Compartilhamento de Dados
          </h5>
          <p>Compartilhamos suas informações apenas quando:</p>
          <ul>
            <li>
              Necessário para o processamento de pagamentos com provedores
              terceirizados;
            </li>
            <li>Exigido por lei ou para cumprimento de obrigações legais;</li>
            <li>Você autorizar explicitamente o compartilhamento.</li>
          </ul>

          <h5 className='textContrastColor ffSoft'>
            4. Armazenamento e Proteção dos Dados
          </h5>
          <p>
            Seus dados são armazenados em servidores seguros e protegidos contra
            acessos não autorizados. Utilizamos criptografia e outras medidas de
            segurança para proteger suas informações.
          </p>
          <p>
            Os dados serão mantidos durante o período necessário para
            cumprimento das finalidades descritas nesta política ou conforme
            exigido por lei.
          </p>

          <h5 className='textContrastColor ffSoft'>5. Direitos do Usuário</h5>
          <p>Você tem os seguintes direitos sobre seus dados pessoais:</p>
          <ul>
            <li>Acessar as informações que temos sobre você;</li>
            <li>
              Solicitar a correção de dados incompletos, inexatos ou
              desatualizados;
            </li>
            <li>
              Solicitar a exclusão dos seus dados pessoais, salvo quando
              necessário para cumprimento de obrigações legais;
            </li>
            <li>Revogar o consentimento para tratamento de dados.</li>
          </ul>
          <p>
            Para exercer seus direitos, envie uma solicitação para{' '}
            <a href='mailto:lgpd@bewook.com'>lgpd@bewook.com</a>.
          </p>

          <h5 className='textContrastColor ffSoft'>
            6. Cookies e Tecnologias de Rastreamento
          </h5>
          <p>
            Utilizamos cookies para melhorar sua experiência no site. Você pode
            configurar seu navegador para bloquear ou alertar sobre esses
            cookies, mas algumas funcionalidades podem ser impactadas.
          </p>

          <h5 className='textContrastColor ffSoft'>
            7. Alterações na Política de Privacidade
          </h5>
          <p>
            Reservamo-nos o direito de alterar esta política a qualquer momento.
            Notificaremos sobre mudanças significativas através de email ou no
            próprio site.
          </p>

          <h5 className='textContrastColor ffSoft'>8. Contato</h5>
          <p>
            Se tiver dúvidas ou preocupações sobre esta política, entre em
            contato conosco:
          </p>
          <ul>
            <li>
              Para questões relacionadas à LGPD:{' '}
              <a href='mailto:lgpd@bewook.com'>lgpd@bewook.com</a>
            </li>
            <li>
              Para suporte: <a href='mailto:help@bewook.com'>help@bewook.com</a>
            </li>
          </ul>
        </div>
      }
    />
  );
}
