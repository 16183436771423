import React, { useEffect, useState } from 'react';
import { get } from '../../../services/requests';
import { apoio } from '../../../services/endpoints';
import '../../../assets/css/indices.css';
import { UFs } from '../../../config/conf';
import { Button, Form, Table } from 'react-bootstrap';

export default function Feriados({ theme = 'light' }) {
  const [feriados, setFeriados] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [uf, setUf] = useState('SP');

  useEffect(() => {
    async function fetchIndices() {
      const response = await get(apoio.feriados.all);
      document.getElementById('uf').value = uf;
      let items = response.data.items;
      items = items
        .map((item) => {
          item.data = item.data.split('T')[0].split('-').reverse().join('/');
          return item;
        })
        .filter((item) => item.uf === uf || item.uf === null)
        .sort((a, b) => {
          return a.data.localeCompare(b.data);
        });

      setFeriados(items);
      setFiltered(items);
    }
    fetchIndices();
  }, [uf]);

  return (
    <>
      <div className='d-flex flex-row align-items-center my-3'>
        <Form.Label
          htmlFor='searchFeriado'
         className='me-2 fs10 m-0'
        >
          Pesquisar
        </Form.Label>
        <Form.Control
          data-bs-theme={theme}
          type='text'
          size='sm'
          className='form-control wp175'
          id='searchFeriado'
          onChange={(e) => {
            const search = e.target.value.toLowerCase();
            const filteredSearch = feriados.filter((indice) => {
              let filtered =
                (indice.data && indice.data.includes(search)) ||
                (indice.nome && indice.nome.toLowerCase().includes(search)) ||
                (indice.tipo && indice.tipo.toLowerCase().includes(search)) ||
                (indice.uf && indice.uf.toLowerCase().includes(search)) ||
                (indice.municipio &&
                  indice.municipio.toLowerCase().includes(search)) ||
                (indice.descricao &&
                  indice.descricao.toLowerCase().includes(search));

              return filtered;
            });
            setFiltered(filteredSearch);
          }}
        />
        <Form.Label
          htmlFor='uf'
          className='fs10 m-0 mx-2 '
        >
          UF
        </Form.Label>
        <Form.Select
          id='uf'
          size='sm'
          data-bs-theme={theme}

          className='form-select form-select-sm wp75 text-center'
          onChange={(e) => {
            setUf(e.target.value);
            const filteredSearch = feriados.filter((indice) => {
              return indice.uf === e.target.value;
            });
            setFiltered(filteredSearch);
          }}
        >
          {UFs &&
            Object.keys(UFs).map((uf) => (
              <option
                key={uf}
                value={uf}
              >
                {uf}
              </option>
            ))}
        </Form.Select>
        <Button
          id='searchClean'
          variant='secondary'
          size='sm'
          className='fs10 ms-2'
          onClick={() => {
            setFiltered(feriados);
            document.getElementById('searchFeriado').value = '';
          }}
        >
          Limpar
        </Button>
      </div>
      <Table
        striped
        hover
        responsive
        data-bs-theme={theme}
        className='fs10'
      >
        <thead>
          <tr>
            <th>Data</th>
            <th>Nome</th>
            <th>Tipo</th>
            <th>UF</th>
            <th>Município</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody>
          {filtered &&
            filtered.map((indice) => (
              <tr key={indice.id}>
                <td>{indice.data}</td>
                <td>{indice.nome}</td>
                <td>{indice.tipo}</td>
                <td>{indice.uf}</td>
                <td>{indice.municipio}</td>
                <td>{indice.descricao}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}
