import {
  FaRegCalendarAlt,
  FaRegCalendarPlus,
  FaWhatsapp,
} from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { LiaTelegramPlane } from 'react-icons/lia';

import { FaPhone } from 'react-icons/fa6';
import { FcPhone } from 'react-icons/fc';

import { BsTrash3Fill } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';

import { FcPlus } from 'react-icons/fc';
import { LuShoppingCart } from 'react-icons/lu';
import { MdExpand, MdOutlineManageSearch } from 'react-icons/md';
import { RiMenuAddLine } from 'react-icons/ri';

import {
  MdAddCircleOutline,
  MdOutlineRemoveCircleOutline,
  MdOutlineKeyboardArrowDown,
} from 'react-icons/md';

import { BsCashCoin } from 'react-icons/bs';
import { CgSearchLoading } from 'react-icons/cg';
import { FcDownload, FcUpload } from 'react-icons/fc';

import { TbFileInvoice } from 'react-icons/tb';

import { AiOutlineLogout, AiOutlineProduct } from 'react-icons/ai';
import { FaRegStar } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import {
  FcAddDatabase,
  FcApproval,
  FcBullish,
  FcCurrencyExchange,
  FcDepartment,
  FcDiploma2,
  FcEngineering,
  FcHome,
  FcLibrary,
  FcMindMap,
  FcMoneyTransfer,
  FcOpenedFolder,
  FcPrint,
  FcRules,
  FcSurvey,
} from 'react-icons/fc';
import { GiSandsOfTime } from 'react-icons/gi';
import { GoTasklist } from 'react-icons/go';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { LiaUsersCogSolid } from 'react-icons/lia';
import { MdOutlineUploadFile } from 'react-icons/md';
import { VscDebugStart } from 'react-icons/vsc';

import {
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineUserDelete,
} from 'react-icons/ai';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { FaRegFile } from 'react-icons/fa';
import { FcCheckmark, FcClock } from 'react-icons/fc';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineAttachFile } from 'react-icons/md';
import { PiFilePdfFill, PiImageFill } from 'react-icons/pi';
import { RxCross2 } from 'react-icons/rx';

import { FaHouseChimney } from 'react-icons/fa6';
import { MdMenu } from 'react-icons/md';
import { PiCurrencyCircleDollarFill } from 'react-icons/pi';
import { TbStarFilled } from 'react-icons/tb';

import { AiOutlineDashboard } from 'react-icons/ai';
import {
  RiLoginBoxLine,
  RiLogoutBoxLine,
  RiArrowGoBackFill,
} from 'react-icons/ri';

import { ui } from '../config/ui';

export default function IconsUI({
  info,
  clsnm = '',
  click = null,
  tooltip = '',
}) {
  switch (info) {
    case ui.social.telegram:
      return (
        <LiaTelegramPlane
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.social.whatsapp:
      return (
        <FaWhatsapp
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.email:
      return (
        <GoMail
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.calendar.add:
      return (
        <FaRegCalendarPlus
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.calendar.reg:
      return (
        <FaRegCalendarAlt
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.phone.color:
      return (
        <FcPhone
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.social.phone.reg:
      return (
        <FaPhone
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.social.message:
      return (
        <BiMessageSquareEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.back:
      return (
        <RiArrowGoBackFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.edit:
      return (
        <FiEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.delete.trash:
      return (
        <BsTrash3Fill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.delete.circle:
      return (
        <MdOutlineRemoveCircleOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.reg:
      return (
        <RiMenuAddLine
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.color:
      return (
        <FcPlus
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.add.circle:
      return (
        <MdAddCircleOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.expand:
      return (
        <MdExpand
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.search:
      return (
        <MdOutlineManageSearch
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.magnifier:
      return (
        <CgSearchLoading
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.action.upload.color:
      return (
        <FcUpload
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.upload.reg:
      return (
        <MdOutlineUploadFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.download:
      return (
        <FcDownload
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.start:
      return (
        <VscDebugStart
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.login:
      return (
        <RiLoginBoxLine
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.action.logout:
      return (
        <RiLogoutBoxLine
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
      case ui.action.arrowdown:
        return (
          <MdOutlineKeyboardArrowDown
            data-toggle='tooltip'
            data-placement='top'
            title={tooltip}
            className={clsnm}
            onClick={click}
          />
        );
    case ui.folder.open:
      return (
        <FcOpenedFolder
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.file.pdf:
      return (
        <PiFilePdfFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.image:
      return (
        <PiImageFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.other:
      return (
        <FaRegFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.file.attach:
      return (
        <MdOutlineAttachFile
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.etapas.simulacao:
      return (
        <FcBullish
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.documentacao_comprador:
      return (
        <FcRules
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.aprovacao_credito:
      return (
        <FcMoneyTransfer
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.documentacao_vendedor:
      return (
        <FcRules
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.documentacao_imovel:
      return (
        <FcHome
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.avaliacao_engenharia:
      return (
        <FcEngineering
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.confirmacao_valores:
      return (
        <FcCurrencyExchange
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.assinatura_formularios:
      return (
        <FcSurvey
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.analise_juridica:
      return (
        <FcLibrary
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.assinatura_contrato:
      return (
        <FcPrint
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.prefeitura:
      return (
        <FcDepartment
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.cartorio:
      return (
        <FcDiploma2
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.finalizado:
      return (
        <FcApproval
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.etapas.mindmap:
      return (
        <FcMindMap
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.status.waiting:
      return (
        <GiSandsOfTime
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.status.done:
      return (
        <IoCheckmarkDoneOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.user.reg:
      return (
        <AiOutlineUser
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.add:
      return (
        <AiOutlineUserAdd
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.user.delete:
      return (
        <AiOutlineUserDelete
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.cross:
      return (
        <RxCross2
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.check.color:
      return (
        <FcCheckmark
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.check.circle:
      return (
        <FaCircleCheck
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.clock:
      return (
        <FcClock
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.add_database:
      return (
        <FcAddDatabase
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.edit:
      return (
        <FiEdit
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.misc.tasks:
      return (
        <GoTasklist
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.star:
      return (
        <TbStarFilled
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.starlight:
      return (
        <FaRegStar
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.house:
      return (
        <FaHouseChimney
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.money:
      return (
        <PiCurrencyCircleDollarFill
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.menu:
      return (
        <MdMenu
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.config:
      return (
        <IoSettingsOutline
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.logout:
      return (
        <AiOutlineLogout
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.dash:
      return (
        <AiOutlineDashboard
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.misc.products:
      return (
        <AiOutlineProduct
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.money.payment:
      return (
        <BsCashCoin
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.money.invoice:
      return (
        <TbFileInvoice
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    case ui.money.subscriber:
      return (
        <LiaUsersCogSolid
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );

    case ui.money.cart:
      return (
        <LuShoppingCart
          data-toggle='tooltip'
          data-placement='top'
          title={tooltip}
          className={clsnm}
          onClick={click}
        />
      );
    default:
      return '';
  }
}

const fileIcon = (type) => {
  if (type.includes('image'))
    return IconsUI({ info: ui.file.image, clsnm: 'svg09 fc-green' });
  if (type.includes('pdf'))
    return IconsUI({ info: ui.file.pdf, clsnm: 'svg09 fc-red' });
  return IconsUI({ info: ui.file.other, clsnm: 'svg09 fc-blur' });
};

export { fileIcon, ui };
