// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Calculos() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Calculos</h4>

      <p>calculos por mes no plano free: 0</p>
      <p>calculos por mes no plano trial: 0</p>

      <p>calculos por mes no plano basic: 2</p>
      <p>calculos por mes no plano pro: 10</p>
      <p>calculos por mes no plano premium: ilimitado</p>

      <p>retençao do calculo</p>
      <p>
        plano basic: 1 mes
      </p>
      <p>
        plano pro: 6 meses
      </p>
      <p>
        plano premium: 1 ano
      </p>

    </div>
  );
}
