import { useContext, useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import { GlobalContext } from '../context/Context';
import '../assets/css/home.css';

import CONF from '../config';
import PackageContext from '../packages/wookie/context/PackageContext';
import Portal from './Portal';

export default function Home() {
  const { loading, setProduto, checkLogin, navigate } = useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const { images } = CONF;
  const [isLogged, setIsLogged] = useState(false);

  const products = [
    {
      name: 'Escritório Digital',
      price: 0,
      logo: images[theme].pad,
      link: '/pad'
    },
    {
      name: 'Juris',
      price: 79,
      logo: images[theme].juris,
      link: '/juris',
    },
    {
      name: 'Assinador de Documentos',
      price: 19,
      logo: images[theme].signer,
      link: '/signer',

    },
    {
      name: 'Calculadora',
      price: 9,
      logo: images[theme].calc,
      link: '/calc',
    },
    // {
    //   name: 'Gestão Empresarial',
    //   price: 59,
    //   logo: images[theme].manage,
    // },
    // {
    //   name: 'Habitacional',
    //   price: 79,
    //   logo: images[theme].habita,
    // },
    // {
    //   name: 'Gestão de Construção',
    //   price: 49,
    //   logo: images[theme].arch,
    // },
    // {
    //   name: 'Clinic',
    //   price: 59,
    //   logo: images[theme].clinic,
    // },
  ];

  useEffect(() => {
    const logged = checkLogin();
    setProduto('global');
    setIsLogged(logged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFull />;

  if (isLogged) return <Portal />;

  return (
    <>
      <NavBar />
      <main className='container'>
        <div className='d-flex flex-column w100 pb-3'>
            <div className='main-home'>
              <img
                src={images[theme].logo}
                alt=''
                className='logo-main'
              />
              <p className='fw400 fs12 py-3'>
                Soluções inteligentes em software
              </p>

              <h5 className='mt-4 ffSoft'>Consulte condições para um software personalizado</h5>
              <span>ou assine um de nossos serviços</span>
            </div>

            <section
              id='assinaturas'
              className='pricing'
            >
              <div className='container'>
                <p className='h3 text-center pb-3 textContrastColor fw600'>Assine já</p>

                <div className='row gy-4'>
                  {products.map((prod, index) => {
                    return (
                      <div className='col-lg-3 col-md-6' key={index}
                      onClick={() => navigate(prod.link)}

                      >
                        <div className='box hpMin300 gocol justify-content-center align-items-center'>
                          <h3>{prod.name}</h3>
                            <span className='textContrastLow fs10 ffSoft'>a partir de</span>
                          <div className='price'>
                            <sup>R$</sup>
                            {prod.price}
                            <span> / mês</span>
                          </div>
                          <img
                            src={prod.logo}
                            className='hp125'
                            alt=''
                          />
                        </div>
                      </div>
                    );
                  })}

                  <p className='py-2 text-center'>
                    Para assinar qualquer serviço, faça login e acesse a área de
                    usuário
                  </p>
                </div>
              </div>
            </section>
        </div>
      </main>
    </>
  );
}
