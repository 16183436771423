import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext, PadContext } from './Context';

export default function PadProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('home');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [logoParams, setLogoParams] = useState({ width: 180, height: 30 });
  const { navigate, checkLogin, store, tenant } = useContext(GlobalContext);

  const { assinantes } = store();
  
  useEffect(() => {
    const logged = checkLogin();
    if (!logged) navigate('/logout');
    const info =
      assinantes && tenant
        ? assinantes.find((assinante) => assinante.TENANT_TENANT === tenant.TENANT_TENANT)
        : {};
    sessionStorage.setItem('@App:infoTenant', JSON.stringify(info));
    // eslint-disable-next-line
  }, []);

  function jurisStore() {
    return {
      ...store(),
    };
  }

  const context = {
    jurisStore,
    navigate,
    loading,
    setLoading,
    section,
    setSection,
    isSubscribed,
    setIsSubscribed,
    logoParams,
    setLogoParams,
  };

  return (
    <PadContext.Provider value={context}>{children}</PadContext.Provider>
  );
}
