import { Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from '../context/Context';

import Loading from '../components/Loading';
import ProtectedRoute from './ProtectedRoute';

// admin
import Admin from '../pages/Admin';

//pad

import Portal from '../pages/Portal';
import Agendou from '../products/Agendou';
import Juris from '../products/juris/Juris';
import Pad from '../products/pad/Pad';
import Wallet from '../pages/Money/Wallet';

// auth
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import Register from '../pages/Auth/Register';
import Recover from '../pages/Auth/Recover';
import Reset from '../pages/Auth/Reset';
import ConfirmEmail from '../pages/Auth/ConfirmEmail';
import Account from '../pages/Account/Account';
import Tenant from '../pages/Account/Tenant';

//signer
// import Signer from '../products/signer/Signer';
// import SignerUpload from '../products/signer/Upload';
// import SignerVerify from '../products/signer/Verify';

// public
import Page404 from '../pages/Handlers/Page404';
import Page500 from '../pages/Handlers/Page500';

import Home from '../pages/Home';

import Privacidade from '../pages/Legal/Privacidade';
import ToDoPrivacy from '../pages/Legal/ToDoPrivacy';
import Termos from '../pages/Legal/Termos';

// import IconsExample from '../dump/IconsExample';

export default function BewookRoutes() {
  const { loading } = useContext(GlobalContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        exact
        path='/'
        element={<Home />}
      />

      {/* <Route
        path='/icons'
        element={<IconsExample />}
      /> */}

      {/* global */}

      <Route
        path='/agendou'
        element={<Agendou />}
      />

      <Route
        path='/pad'
        element={<Pad />}
      />

      <Route
        path='/login'
        element={<Login />}
      />

      <Route
        path='/confirmar/:codigo'
        element={<ConfirmEmail />}
      />

      <Route
        path='/logout'
        element={<Logout />}
      />
      <Route
        path='/register'
        element={<Register />}
      />
      <Route
        path='/recover'
        element={<Recover />}
      />
      <Route
        path='/reset'
        element={<Reset />}
      />

      {/* public */}

      <Route
        path='/privacidade'
        element={<Privacidade />}
      />

      <Route
        path='/todo/privacidade'
        element={<ToDoPrivacy />}
      />

      <Route
        path='/termos'
        element={<Termos />}
      />

      <Route
        path='/500'
        element={<Page500 />}
      />

      <Route
        path='/404'
        element={<Page404 />}
      />
      <Route
        path='*'
        element={<Page404 />}
      />

      <Route
        path='/wallet'
        element={
          <ProtectedRoute>
            <Wallet />
          </ProtectedRoute>
        }
      />

      <Route
        path='/wallet/:subscription'
        element={
          <ProtectedRoute>
            <Wallet />
          </ProtectedRoute>
        }
      />

      <Route
        path='/portal'
        element={
          <ProtectedRoute>
            <Portal />
          </ProtectedRoute>
        }
      />

      <Route
        path='/juris/:tenant'
        element={
          <ProtectedRoute>
            <Juris />
          </ProtectedRoute>
        }
      />

      <Route
        path='juris'
        element={
          <ProtectedRoute>
            <Juris />
          </ProtectedRoute>
        }
      />

      {/* admin */}

      <Route
        path='/admin'
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />

      {/* auth */}

      <Route
        path='/conta/:tenant'
        element={
          <ProtectedRoute>
            <Tenant />
          </ProtectedRoute>
        }
      />

      <Route
        path='/conta'
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
