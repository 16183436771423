import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { GlobalContext } from './Context';
import * as api from '../services/requests';
import { getFirstWord } from '../assets/js/helpers';
import { login as makeLogin, logout as makeLogout } from '../services/auth';
import {
  // assinantes as getAssinantes,
  plans_products,
  subscriptionsByUser,
} from '../services/views';
import {
  // tenantsUsers as getTenantsUsers,
  // tenantsServices as getTenantServices,
  tenantsByUsers,
} from '../services/tenants';
import { services as getServices } from '../services/services';
import { jwt } from '../services/misc';

export default function GlobalProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [produto, setProduto] = useState('global');
  const [tenant, setTenant] = useState(null);
  const [redirectPath, setRedirectPath] = useState('/');
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem('@App:token'),
  );

  const [available, setAvailable] = useState({
    services: [],
    tenants: [],
    products: [],
    plansProducts: [],
    subscriptions: [],
    needsFetch: true,
  });

  const [userName, setUserName] = useState();

  const [cookies, setCookie, removeCookie] = useCookies(['token', 'ip']);

  const cookiesProps = {
    path: '/',
    maxAge: 60 * 60 * 24,
    // sameSite: 'Strict',
    // domain: 'www.bewook.com',
    // secure: true,
    // httpOnly: true,
    // domain: 'localhost',
    // secure: false,
    // httpOnly: false,
  };

  function store() {
    return {
      token: cookies.token,
      appServices: JSON.parse(sessionStorage.getItem('@App:appServices')),
      appToken: sessionStorage.getItem('@App:token'),
      ip: cookies.ip,
    };
  }

  function storeUpdate(param, value) {
    if (cookies[param]) {
      setCookie(param, value, cookiesProps);
    }
    return {
      ...store(),
      [param]: value,
    };
  }

  function checkLogin() {
    const { token, appToken } = store();
    if (!token || !appToken) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setIsAuthenticated(checkLogin());
    const token = store().token;
    api.defaults.headers.Authorization = token;
    setLoading(false);
    // eslint-disable-next-line
  }, [cookies]);

  const subscriptionsByTenant = (subs) => {
    return subs.reduce((acc, curr) => {
      if (acc[curr.TENANT]) {
        acc[curr.TENANT].subs.push(curr);
        if (acc[curr.TENANT].products[curr.PTS]) {
          acc[curr.TENANT].products[curr.PTS].subs.push(curr);
        } else {
          acc[curr.TENANT].products[curr.PTS] = {
            subs: [curr],
          };
        }
      } else {
        acc[curr.TENANT] = {
          tenant: curr.TENANT,
          subs: [curr],
          products: {},
        };
        acc[curr.TENANT].products[curr.PTS] = {
          subs: [curr],
        };
      }
      return acc;
    }, {});
  };

  const fetchInfo = async () => {
    // const res = await plans_products();

    const [plans, tenants, subscriptions, services] = await Promise.all([
      plans_products(),
      tenantsByUsers(),
      subscriptionsByUser(),
      getServices(),
    ]);

    const resPlans = plans.data;
    const resTenantsByUser = tenants.data;
    const resSubscriptions = subscriptions.data;
    const resServices = services.data;

    const subs =
      resSubscriptions && subscriptionsByTenant(resSubscriptions);

    const produtos =
      resPlans &&
      Array.from(
        new Map(
          resPlans
            .filter((item) => item.A === 1)
            .map((item) => [
              item.PRODUCT,
              {
                G: item.PTG,
                NOME: item.PRODUCT,
                DESCRICAO: item.DESCRIPTION,
                SERVICE: item.PTS,
                COD: item.PTC,
              },
            ]),
        ).values(),
      );

    setAvailable({
      ...available,
      plansProducts: resPlans,
      products: produtos,
      tenants: resTenantsByUser,
      subscriptions: subs,
      services: resServices,
      needsFetch: false,
    });
  };

  useEffect(() => {
    if (!userName) {
      const user = async () => {
        const { token, appToken } = store();

        if (!token || !appToken) {
          setIsAuthenticated(false);
          return;
        }

        const res = await jwt('NOME');
        const { key } = res.data;
        if (key === 'TOKEN_INVALID' || key === 'NO_DATA') {
          setLoading(true);
          await logout();
          setLoading(false);
          
        } else {
          setUserName(getFirstWord(res.data.data));
        }
      };
      user();
    }

    if (available.needsFetch) {   
      fetchInfo();
    }
    // eslint-disable-next-line
  }, [userName, available]);

  const removeAspas = (str) => {
    return str.replace(/['"]+/g, '');
  };

  async function login(userData) {
    const response = await makeLogin(userData);
    const { success } = response.data;

    if (!success) {
      setIsAuthenticated(false);
      return response.data;
    } else {
      setIsAuthenticated(true);
      const { token, user } = response.data;
      sessionStorage.setItem('@App:token', removeAspas(JSON.stringify(token)));
      api.defaults.headers.Authorization = token;

      setUserName(getFirstWord(user));

      await fetchInfo();

      setCookie('token', token, cookiesProps);
     
      return response.data;
    }
  }

  async function logout(full = false) {
    await makeLogout({ full });
    removeCookie('token');
    setIsAuthenticated(false);
    sessionStorage.clear();
    localStorage.clear();
    api.defaults.headers.Authorization = '';
    navigate('/');
  }

  const context = {
    login,
    logout,
    store,
    storeUpdate,
    cookies,
    setCookie,
    cookiesProps,
    checkLogin,
    navigate,
    loading,
    setLoading,
    produto,
    setProduto,
    redirectPath,
    setRedirectPath,
    isAuthenticated,
    setIsAuthenticated,
    // svc,
    // setSvc,
    tenant,
    setTenant,
    available,
    setAvailable,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
