import { useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import { GlobalContext } from '../context/Context';
// import { data_br } from '../assets/js/helpers';
import CONF from '../config';
import PackageContext from '../packages/wookie/context/PackageContext';
import ContainerBox from '../packages/wookie/box/ContainerBox';

// import { IconsUI, ui } from '../packages/wookie/icons';

export default function Portal() {
  const { images } = CONF;
  const { checkLogin, loading, navigate, available, setProduto, tenant } =
    useContext(GlobalContext);
  const { theme } = useContext(PackageContext);

  const { subscriptions } = available;
  console.log({ subscriptions });
  // const [service, setService] = useState('');

  useEffect(() => {
    setProduto('global');
    const logged = checkLogin();
    if (!logged) navigate('/logout');
    console.log({ available, tenant });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFull />;

  return (
    <ContainerBox
      navbar={<NavBar tenantMenu />}
      main={
        <main className='container gocol'>
          <div className='d-flex flex-row w100'>
            <section className='main-container-render'>
              {tenant ? (
                <div>
                  <h2 className='ffSoft'>Olá, {tenant.NAME}</h2>
                  <p>Selecione um serviço para acessar</p>

                  <div className='d-flex flex-row justify-content-start'>
                    {tenant &&
                      subscriptions[tenant.TENANT].products &&
                      Object.keys(subscriptions[tenant.TENANT].products).map(
                        (prod) => {
                          const prodObj =
                            subscriptions[tenant.TENANT].products[prod].subs;
                          return (
                            <div
                              key={prod}
                              className='box-product'
                            >
                              <div className='p-2'>
                                <h5>{prod}</h5>
                                <img
                                  src={images[theme][prodObj[0].PTS]}
                                  className='img-logo-product'
                                  alt=''
                                />
                              </div>
                            </div>
                          );
                        },
                      )}
                  </div>
                </div>
              ) : (
                <div>
                  <h2>Selecione um serviço</h2>
                  <p>Selecione uma conta para acessar</p>
                </div>
              )}
            </section>
          </div>
        </main>
      }
    />
  );
}
