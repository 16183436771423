// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Painel() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Painel</h4>
    </div>
  );
}
