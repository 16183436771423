import React from 'react';
// import { get } from '../../../services/requests';
// import { apoio } from '../../../services/endpoints';
import '../../../assets/css/indices.css';
import { Button, Form, Table } from 'react-bootstrap';
// import { UFs } from '../../../config/conf';
// import {JurisContext} from '../../../context/Context';
export default function Advogados({ theme = 'light' }) {
  // const [advogados, setAdvogados] = useState([]);
  // const { jurisStore } = useContext(JurisContext);

  // const productRota = (window.location.href).split('/')[3];

  // const plano = jurisStore().assinantes.filter((assinante) => assinante.PLANO_ROTA === productRota);

  // useEffect(() => {
  //   async function fetchIndices() {
  //     const response = await get(apoio.feriados.all);
  //     document.getElementById('uf').value = uf;
  //     let items = response.data.items;
  //     items = items
  //       .map((item) => {
  //         item.data = item.data.split('T')[0].split('-').reverse().join('/');
  //         return item;
  //       })
  //       .filter((item) => item.uf === uf || item.uf === null)
  //       .sort((a, b) => {
  //         return a.data.localeCompare(b.data);
  //       });

  //     setFeriados(items);
  //     setFiltered(items);
  //   }
  //   fetchIndices();
  // }, [uf]);

  return (
    <>
      <div>
        {/* filter */}
        <div className='d-flex flex-row align-items-center my-3'>
          <Form.Label
            htmlFor='searchAdvogado'
            className='me-2 fs10 m-0'
          >
            Pesquisar
          </Form.Label>
          <Form.Control
            data-bs-theme={theme}
            type='text'
            size='sm'
            className='form-control wp200'
            id='searchAdvogado'
            // onChange={(e) => {
            //   const search = e.target.value.toLowerCase();
            //   const filteredSearch = feriados.filter((indice) => {
            //     let filtered =
            //       (indice.data && indice.data.includes(search)) ||
            //       (indice.nome && indice.nome.toLowerCase().includes(search)) ||
            //       (indice.tipo && indice.tipo.toLowerCase().includes(search)) ||
            //       (indice.uf && indice.uf.toLowerCase().includes(search)) ||
            //       (indice.municipio &&
            //         indice.municipio.toLowerCase().includes(search)) ||
            //       (indice.descricao &&
            //         indice.descricao.toLowerCase().includes(search));

            //     return filtered;
            //   });
            //   setFiltered(filteredSearch);
            // }}
          />

          <Button
            id='searchClean'
            variant='secondary'
            size='sm'
            className='fs10 ms-2'
            // onClick={() => {
            //   setFiltered(feriados);
            //   document.getElementById('searchAdvogado').value = '';
            // }}
          >
            Limpar
          </Button>
        </div>
      </div>
      <Table 
        striped
        hover
        responsive
        data-bs-theme={theme}
        className='fs10'
        >
        <thead>
          <tr>
            <th>Data</th>
            <th>Nome</th>
            <th>Tipo</th>
            <th>UF</th>
            <th>Município</th>
            <th>Descrição</th>
          </tr>
        </thead>
        <tbody>
          {/* {filtered &&
            filtered.map((indice) => (
              <tr key={indice.id}>
                <td>{indice.data}</td>
                <td>{indice.nome}</td>
                <td>{indice.tipo}</td>
                <td>{indice.uf}</td>
                <td>{indice.municipio}</td>
                <td>{indice.descricao}</td>
              </tr>
            ))} */}
        </tbody>
      </Table>
    </>
  );
}
