export async function consultarCNPJ(cnpj) {
  if (!cnpj) throw new Error('CNPJ não informado');
  let sanitized = cnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '');
  // cnpj = cnpj.replace(/[^0-9]/g, '');
  const res = await fetch(`https://publica.cnpj.ws/cnpj/${sanitized}`);
  const data = await res.json();
  return data;
}

export async function consultarCEP(cep) {
  if (!cep) throw new Error('CEP não informado');
  let sanitized = cep.replaceAll('-', '');
  const res = await fetch(`https://viacep.com.br/ws/${sanitized}/json/`);
  const data = await res.json();
  return data;
}
