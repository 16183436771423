import API from './requests';
import { TENANTS } from './endpoints';

export async function tenantsUsers() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(TENANTS.users.read.user, {
    token,
  });
  return response;
}

export async function tenantsByUsers() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(TENANTS.tenant.read.by_user, {
    token,
  });
  return response;
}

export async function tenantsServices() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(TENANTS.services.read.all, {
    token,
  });
  return response;
}

export async function linkServices(tenant, service_name) {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(TENANTS.services.create, {
    token,
    tenant,
    service_name,
  });
  return response;
}

export async function unlinkServices(codigo) {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(TENANTS.services.delete, {
    token,
    codigo,
  });
  return response;
}

export async function getBlob(
  blobCode,
  blobTable,
  blobColumn,
  tenantCode,
  tenant,
  token,
) {
  
  try {
    const form = new FormData();
    let endpoint;
    form.append('codigo', blobCode);
    form.append('tenant', tenant);
    form.append('tenant_codigo', tenantCode);
    form.append('token', token);

    switch (blobTable) {
      case 'TENANTS':
        switch (blobColumn) {
          case 'LOGO':
            endpoint = TENANTS.tenant.read.logo;
            break;
          case 'TIMBRADO':
            endpoint = TENANTS.tenant.read.timbrado;
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    const response = await API(endpoint, form, true);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error(error);
  }
}
