import API from './requests';
import { VIEWS } from './endpoints';

export async function assinantes() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(VIEWS.tenants_assinantes_users, {
    token,
  });
  return response;
}

export async function plans_products() {
  const response = await API(VIEWS.pub.planos_produtos, {});
  return response;
}

export async function subscriptionsByUser() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(VIEWS.subscriptions.by_user, {
    token,
  });
  return response;
}
