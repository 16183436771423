import axios from 'axios';
import { OCI } from './endpoints';

export const defaults = {
  headers: {
    Authorization: '',
  },
};

export async function post(endpoint, body) {
  const api = axios.create({
    baseURL: OCI,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const token = sessionStorage.getItem('@App:token');
  if (token) {
    api.defaults.headers.Authorization = token;
  }
  const response = await api.post(`${endpoint}/`, body);
  return response;
}

export async function get(endpoint) {
  const api = axios.create({
    baseURL: OCI,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  if (token) {
    api.defaults.headers.Authorization = token;
  }
  response = await api.get(`${endpoint}/`);
  return response;
}

export const postFiles = async (endpoint, body) => {
  const token = sessionStorage.getItem('@App:token');
  const apiFiles = axios.create({
    baseURL: OCI,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (token) {
    apiFiles.defaults.headers.Authorization = token;
  }
  const response = await apiFiles.post(`${endpoint}/`, body);
  return response;
};

export default async function API(endpoint, body, fetchAPI = false) {
  if (body === undefined || body === null) {
    body = {};
  }

  const CONTENT = () => {
    switch (endpoint.content) {
      case 'files':
        return 'multipart/form-data';
      default:
        return 'application/json';
    }
  };

  const content = CONTENT();

  const api = axios.create({
    baseURL: endpoint.base,
    headers: {
      'Content-Type': content,
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');

  if (token) {
    api.defaults.headers.Authorization = token;
    body.token = token;
  }

  if (fetchAPI) {
    response = await fetch(`${endpoint.base}${endpoint.endpoint}`, {
      method: endpoint.method,
      body: body,
    });
    return response;
  }

  if (endpoint.method === 'post') {
    response = await api.post(endpoint.endpoint, body);
  } else {
    response = await api.get(endpoint.endpoint);
  }

  if (
    response.data.success === false &&
    response.data.message === 'Token inválido'
  ) {
    window.location.href = '/logout';
  }

  return response;
}
