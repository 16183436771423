// import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';

export default function Tasks() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Tasks</h4>
      incluir painel de tarefas/prazos

      <p>
        tarefas por mes no plano free: 5
      </p>

      <p>
        tarefas por mes no plano trial: 5
      </p>

      <p>
        tarefas por mes no plano basic: 20
      </p>

      <p>
        tarefas por mes no plano pro: 50
      </p>

      <p>
        tarefas por mes no plano premium: ilimitado
      </p>
    </div>
  );
}
