import React, { useState } from 'react';
import { CgSearchLoading } from 'react-icons/cg';
import { FcDownload } from 'react-icons/fc';
import { MdExpand } from 'react-icons/md';
import '../assets/css/app/blobviewer.css';
import * as end from '../services/endpoints';
import { Button } from 'react-bootstrap';

import {IconsUI, ui} from '../packages/wookie/icons';

const BlobViewer = ({
  blobCode,
  blobType,
  blobIcon,
  blobTable,
  tenantCode,
  tenant,
  token,
  clsnm = '',
}) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadBlobContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData();
      let endpoint;
      form.append('codigo', blobCode);
      form.append('tenant', tenant);
      form.append('tenant_codigo', tenantCode);
      form.append('token', token);

      switch (blobTable) {
        case 'TENANTS':
          endpoint = end.tenants.tenant.read.logo;
          break;

        default:
          break;
      }

      const url = `${end.OCI}${endpoint}/`;
      const response = await fetch(url, {
        method: 'POST',
        body: form,
      });

      // console.log('response', response);
      const blob = await response.blob();

      const objectURL = URL.createObjectURL(blob);
      setUrl(objectURL);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className={`d-flex flex-column ${clsnm}`}>
      <div className='d-flex flex-row justify-content-start align-items-center'>
        <span className='me-2'>{blobIcon}</span>

        <CgSearchLoading
          className='svg09 cBlue me-1 btn-pointer'
          onClick={(e) => {
            loadBlobContent(e);
            const el = document.getElementById(`cont_doc_${blobCode}`);
            if (el.classList.contains('divHide')) {
              el.classList.toggle('divHide');
            }
          }}
        />
        <MdExpand
          className='svg09 cBlue me-1 btn-pointer'
          onClick={(e) => {
            const el = document.getElementById(`cont_doc_${blobCode}`);
            el.classList.toggle('divHide');
            if (!el.classList.contains('divHide')) {
              if (!url) {
                loadBlobContent(e);
              }
            }
          }}
        />
        <Button
          variant='link'
          className='noUnder fs10 cLink'
          onClick={() => {
            document
              .getElementById(`cont_doc_${blobCode}`)
              .classList.toggle('bgWhite');
          }}
        >
          <IconsUI
            info={ui.mode.light}
            id='light'
            clsnm={`m-1`}
            tooltip='Tema Claro'
          />
        </Button>
      </div>
      <div
        className='content-doc divHide fs10 ffSoft py-3 px-4 rounded'
        id={`cont_doc_${blobCode}`}
      >
        {loading ? (
          'Carregando...'
        ) : url ? (
          blobType.includes('image') ? (
            <>
              <a
                href={url}
                download
                className='mb-2 fs08 gorow'
              >
                <FcDownload className='svg14 cBlue' />
                <span className='ms-1'>Baixar Imagem</span>
              </a>
              <img
                src={url}
                alt='img'
                className='img_iframe'
                id={blobCode}
              />
            </>
          ) : blobType === 'application/pdf' ? (
            <iframe
              title='PDF Viewer'
              src={url}
              className='pdf_iframe'
            />
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default BlobViewer;
