import API from './requests';
import { AUTH } from './endpoints';

export async function register(cpf, nome, email, telefone, password) {
  const response = await API(AUTH.register, {
    cpf,
    nome,
    email,
    telefone,
    password,
  });
  return response;
}

export async function reset(email, cpf, password, otp) {
  const response = await API(AUTH.reset, {
    email,
    cpf,
    password,
    otp,
  });
  return response;
}

export async function confirmer(email, cpf, otp, codigo) {
  const response = await API(AUTH.confirm, {
    email,
    cpf,
    otp,
    codigo
  });
  return response;
}

export async function recover(email, cpf) {
  const response = await API(AUTH.recover, {
    email,
    cpf,
  });
  return response;
}

export async function login(userData) {
  const endpoint = userData.v ? AUTH.vortex.login : AUTH.login;
  const response = await API(endpoint, userData);
  return response;
}

export async function logout({ full = false }) {
  const response = await API(AUTH.logout, { full });
  return response;
}

export async function otp(email, cpf, reason) {
  const response = await API(AUTH.otp, {
    email,
    cpf,
    reason,
  });
  return response;
}