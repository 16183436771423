import React, { useState } from "react";
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';

export default function Agendou(){
 
    const [availableSlots, setAvailableSlots] = useState([
      '9:00 AM', '10:00 AM', '11:00 AM', '2:00 PM', '3:00 PM', '4:00 PM',
    ]);
    const [selectedSlot, setSelectedSlot] = useState(null);
  
    const handleSlotClick = (slot) => {
      setSelectedSlot(slot);
      setAvailableSlots(availableSlots.filter((s) => s !== slot)); // Remove the selected slot
    };
  
    return (
      <Container className="mt-5">
        <Row className="text-center mb-4">
          <Col>
            <h1>Schedule an Appointment</h1>
            <p>Select an available time slot below:</p>
          </Col>
        </Row>
  
        <Row className="mb-3">
          {availableSlots.map((slot, index) => (
            <Col xs={6} sm={4} md={3} className="mb-3" key={index}>
              <Button
                variant="primary"
                className="w-100"
                onClick={() => handleSlotClick(slot)}
              >
                {slot}
              </Button>
            </Col>
          ))}
        </Row>
  
        {availableSlots.length === 0 && (
          <Row className="text-center">
            <Col>
              <Alert variant="warning">No slots available</Alert>
            </Col>
          </Row>
        )}
  
        {selectedSlot && (
          <Row className="text-center mt-4">
            <Col>
              <Alert variant="success">
                You have scheduled an appointment at <strong>{selectedSlot}</strong>.
              </Alert>
            </Col>
          </Row>
        )}
      </Container>
    );
};
