import { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import NavBar from '../../components/NavBar';
import { GlobalContext } from '../../context/Context';
import { data_br, currFormat } from '../../assets/js/helpers';
import { IMaskInput } from 'react-imask';
import { tenants } from '../../services/endpoints';
import { post } from '../../services/requests';
import Loading from '../../components/Loading';

import { IconsUI, ui } from '../../packages/wookie/icons';
import PackageContext from '../../packages/wookie/context/PackageContext';
import ContainerBox from '../../packages/wookie/box/ContainerBox';
import LoadingDots from '../../packages/wookie/common/LoadingDots';
import CONF from '../../config';

import { getCEP } from '../../assets/js/helpers';
import API from '../../services/requests';
import END, { MP } from '../../services/endpoints';

// import { initMercadoPago } from '@mercadopago/sdk-react';
// initMercadoPago('TEST-be1fef3b-8d8a-43a8-a9b7-3101280f7948');

export default function Account() {
  const { navigate, checkLogin, store, setCookie, cookiesProps } =
    useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const { user, email, cpf, telefone, cep, endnum, assinantes, tenantsUsers } =
    store();

  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState({});

  const [tenTenant, setTenTenant] = useState('');
  const [tenEndereco, setTenEndereco] = useState('');
  const [tenCidade, setTenCidade] = useState('');
  const [tenNome, setTenNome] = useState('');
  const [tenTelefone, setTenTelefone] = useState('');
  const [tenEmail, setTenEmail] = useState('');
  const [tenMessage, setTenMessage] = useState('');
  const [userTenants, setUserTenants] = useState(tenantsUsers);

  const [showAddSubscription, setShowAddSubscription] = useState([]);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [planos, setPlanos] = useState([]);
  const [addPlano, setAddPlano] = useState('');

  const logged = checkLogin();

  const updateInfo = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('@App:token');
      const assinantes = await API(END.global.views.tenants_assinantes_users, {
        token,
      });
      const tenantsUsers = await API(END.global.tenants.users.read.user, {
        token,
      });
      const planos = await API(END.global.views.planos_produtos_sm, {});
      const meliPlanos = await API(END.global.meli.planos.read, {});

      console.log(meliPlanos);

      const mytenants = await post(tenants.users.read.user, {});

      setUserTenants(mytenants.data);
      setPlanos(planos.data);

      sessionStorage.setItem(
        '@App:assinantes',
        JSON.stringify(assinantes.data),
      );
      sessionStorage.setItem(
        '@App:tenantsUsers',
        JSON.stringify(tenantsUsers.data),
      );

      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleToggleModal = (index, modal, prevStates) => {
    let newStates = [];
    switch (modal) {
      case 'subscription':
        newStates = [...prevStates];
        newStates[index] = !newStates[index];
        setShowAddSubscription(newStates);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // if (!logged) navigate('/');
    setShowAddSubscription(userTenants && userTenants.map(() => false));
    const end = async () => {
      const res = await getCEP(cep, endnum);
      setAddress(res);
    };
    end();
    const getPlanos = async () => {
      try {
        const planos = await API(END.global.views.planos_produtos_sm, {});
        const meliPlanos = await API(MP.core.planos.read.all, {});
        const planosEdit = planos.data.map((plano) => {
          const meliResults = meliPlanos.data.results;
          const MP = {
            MP_PLAN_ID: '',
            MP_PLAN_NAME: '',
            MP_PLAN_PRICE: '',
            MP_TRIAL_DAYS: '',
            MP_STATUS: '',
            MP_FREQUENCY: '',
          };
          if (plano.PLANO_PADRAO) {
            const meliPlano = meliResults.find(
              (mp) => mp.id === plano.PLANO_PADRAO,
            );
            if (meliPlano) {
              MP.MP_PLAN_ID = meliPlano.id;
              MP.MP_PLAN_NAME = meliPlano.reason;
              MP.MP_PLAN_PRICE = meliPlano.transaction_amount;
              MP.MP_TRIAL_DAYS = meliPlano.free_trial
                ? meliPlano.free_trial.frequency
                : 0;
              MP.MP_STATUS = meliPlano.status;
              MP.MP_FREQUENCY = meliPlano.frequency;
            }
          }
          return {
            ...plano,
            ...MP,
          };
        });

        setPlanos(planosEdit);
      } catch (error) {
        console.log(error);
      }
    };
    if (cep) {
      const end = getCEP(cep);
      setAddress(end);
    }
    getPlanos();
    // eslint-disable-next-line
  }, []);

  const editConta = () => {
    const conta = document.getElementById('tenTenant').value;
    const tenant = conta.replace(/[^a-zA-Z]/g, '').toLowerCase();
    document.getElementById('tenTenant').value = tenant;
  };

  const checkTenant = async (e) => {
    setIsLoading(true);
    setTenTenant(e.target.value);
    try {
      const response = await post(tenants.tenant.check, {
        conta: e.target.value,
      });
      const count = response.data;
      if (count > 0) {
        setTenMessage('Conta já existe');
        document.getElementById('submitTenant').disabled = true;
      } else {
        setTenMessage('');
        document.getElementById('submitTenant').disabled = false;
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createTenant = async () => {
    setIsLoading(true);
    try {
      await post(tenants.tenant.create, {
        conta: tenTenant,
        nome: tenNome,
        email: tenEmail,
        whatsapp: tenTelefone,
        endereco: tenEndereco,
        cidade: tenCidade,
      });
      await updateInfo();
      // window.location.reload();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleAddAssinante = async (tenant) => {
    setIsLoading(true);
    try {
      await API(END.global.assinantes.create, {
        tenant_codigo: tenant,
        plano_codigo: addPlano,
      });
      await updateInfo();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const buttonAtivar = (active, external_reference, assinante) => {
    if (active)
      return (
        <IconsUI
          info={ui.misc.check.color}
          clsnm='svg16'
        />
      );

    if (
      cep === '' ||
      endnum === '' ||
      !address ||
      cep === null ||
      endnum === null
    )
      return <span className='fw600 cRed fs10'>Preencha o Endereço</span>;

    return (
      <Button
        variant='primary'
        size='sm'
        className='fs10'
        onClick={async () => {
          const payload = {
            external_reference,
            assinante,
            address,
            // plan_id:
          };

          navigate(`/wallet/${external_reference}`, {
            state: { payload },
          });
        }}
      >
        Ativar
      </Button>
    );
  };

  const tenantsTable =
    userTenants &&
    userTenants.map((tenant, index) => {
      const filteredAssinantes =
        assinantes &&
        assinantes.filter(
          (assinante) => assinante.TENANT_TENANT === tenant.TENANT_TENANT,
        );

      const tableFilteredAssinantes =
        filteredAssinantes &&
        filteredAssinantes.map((assinante, index) => (
          <tr
            key={index}
            className='vAlignMiddle fs12'
          >
            <td>{assinante.PRODUCT_NOME}</td>
            <td>{assinante.PLANO_NOME}</td>
            <td>
              {buttonAtivar(
                assinante.ASSINANTE_ACTIVE,
                assinante.ASSINANTE_CODIGO,
                assinante,
              )}
            </td>
            <td>{assinante.FREQUENCIA_NOME}</td>
            <td>
              {assinante.FREQUENCIA_ID === 1
                ? '-'
                : data_br(assinante.EXPIRATION)}
            </td>
            <td>{assinante.ROLE_DESCRIPTION}</td>
            <td>
              <IconsUI
                info={ui.action.edit}
                clsnm='textContrastLow mousePointerHover'
                tooltip='Acessar detalhes'
              />
            </td>
          </tr>
        ));

      function addSubscription() {
        if (tenant.ROLE_PRIORITY > 201) return '';

        if (
          cep === '' ||
          endnum === '' ||
          !address ||
          cep === null ||
          endnum === null
        )
          return (
            <span className='fw600 cRed fs10'>
              É necessário preencher o endereço para adicionar ou ativar
              assinaturas
            </span>
          );

        return (
          <>
            <Modal
              centered
              size='lg'
              data-bs-theme={theme}
              show={showAddSubscription[index]}
              onHide={() =>
                handleToggleModal(index, 'subscription', showAddSubscription)
              }
            >
              <Modal.Header closeButton>
                <Modal.Title className='textContrastColor'>
                  {tenant.TENANT_TENANT}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='my-2'>Adicionar nova assinatura à conta</p>

                <Form
                  id='mtenant_form_add_subscription'
                  className='fs10'
                >
                  <Form.Group
                    className='mb-3 d-flex flex-row justify-content-between align-items-center'
                    controlId='form_tenant_modal_select_perfil'
                  >
                    <span className='wp125 fs12'>Selecionar Plano</span>
                    <Form.Select
                      aria-label='Selecionar plano'
                      size='sm'
                      onChange={(e) => setAddPlano(e.target.value)}
                    >
                      <option value=''></option>
                      {planos &&
                        planos.map((plano, index) => (
                          <option
                            key={index}
                            value={plano.PLANO_CODIGO}
                          >
                            {plano.PRODUCT_NOME}
                            {' | '}
                            {plano.PLANO_NOME}
                            {' | '}
                            {plano.FREQUENCIA_NOME}
                            {' | '}
                            {currFormat(plano.PLANO_VALOR)}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>

                  <div className='d-flex justify-content-center'>
                    {isLoading ? (
                      <Loading class_op='loading' />
                    ) : (
                      <Button
                        variant='primary'
                        type='submit'
                        size='sm'
                        onClick={() => handleAddAssinante(tenant.TENANT_TENANT)}
                      >
                        Adicionar Assinatura ao Tenant:{' '}
                        <b>{tenant.TENANT_TENANT}</b>
                      </Button>
                    )}
                  </div>
                </Form>
              </Modal.Body>
            </Modal>

            <div className='d-flex flex-row justify-content-end'>
              <Button
                variant='primary'
                id={index}
                className='btn btn-sm'
                onClick={() => {
                  handleToggleModal(index, 'subscription', showAddSubscription);
                }}
              >
                Adicionar Assinatura
              </Button>
            </div>
          </>
        );
      }

      return (
        <div
          key={index}
          className='w100'
        >
          <h5 className='mt-4'>
            <a
              href={`/conta/${tenant.TENANT_TENANT}`}
              className='cLink noUnder fs16'
            >
              <IconsUI
                info={ui.misc.star}
                clsnm='me-1 textContrastColor'
              />
              {tenant.TENANT_TENANT}
            </a>
          </h5>

          {filteredAssinantes.length > 0 ? (
            <Table
              striped
              hover
              responsive
              data-bs-theme={theme}
              className='fs10 w100'
            >
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Plano</th>
                  <th>Status</th>
                  <th>Periodicidade</th>
                  <th>Vencimento</th>
                  <th>Perfil</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{tableFilteredAssinantes}</tbody>
            </Table>
          ) : (
            <p className='textContrastLow'>
              Não há assinaturas para esta conta
            </p>
          )}
          {addSubscription()}
        </div>
      );
    });

  if (isLoading) return <LoadingDots conf={CONF} />;

  return (
    <ContainerBox
      navbar={<NavBar logged={logged} />}
      classMain='mx-2'
      main={
        <main className='container gocol align-items-start my-5'>
          <p className='mt-2'>Olá, {user}!</p>

          <h5 className='mt-4 ffSoft fw500 textContrastColor'>
            Meus Dados Pessoais
          </h5>
          <div className='mt-3 gocol'>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>Nome</span>
              <span>{user}</span>
            </p>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>CPF</span>
              <span>{cpf}</span>
            </p>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>Email</span>
              <span>{email}</span>
            </p>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>Telefone</span>
              <span>{telefone}</span>
            </p>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>CEP</span>
              <span>{cep}</span>
            </p>
            <p className='gorow align-items-center my-0'>
              <span className='textContrastLow fw300 wp100'>Endereço</span>
              <span>{address && address.full}</span>
            </p>
            <Modal
              centered
              size='lg'
              data-bs-theme={theme}
              show={showEditAddress}
              onHide={() => setShowEditAddress(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Editar Endereço</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className='mb-3'>
                    <Form.Label htmlFor='cep'>CEP</Form.Label>
                    <Form.Control
                      type='text'
                      name='cep'
                      id='cep'
                      placeholder='CEP'
                    />
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Form.Label htmlFor='endnum'>Número</Form.Label>
                    <Form.Control
                      type='text'
                      name='endnum'
                      id='endnum'
                      placeholder='Número'
                    />
                  </Form.Group>

                  <Button
                    variant='primary'
                    onClick={() => {
                      const newCep = document.getElementById('cep').value;
                      const newEndnum = document.getElementById('endnum').value;
                      const getAddress = async () => {
                        const end = await getCEP(newCep, newEndnum);
                        setAddress(end);
                      };
                      getAddress();

                      const updateDB = async () => {
                        await API(END.global.users.update.end, {
                          cep: newCep,
                          endnum: newEndnum,
                        });
                      };

                      if (newCep && newEndnum) {
                        updateDB();
                        setCookie('cep', newCep, cookiesProps);
                        setCookie('endnum', newEndnum, cookiesProps);
                        setShowEditAddress(false);
                      }
                    }}
                  >
                    Salvar
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>

            <Button
              variant='primary'
              className='btn btn-sm mt-2 wp150'
              onClick={() => setShowEditAddress(true)}
            >
              <IconsUI
                info={ui.action.edit}
                clsnm='me-2'
                sideText='Editar endereço'
              />
            </Button>

            <span className='fw-light fs10 textContrastLow'>
              O endereço será utilizado para validação quando você assinar
              planos ou serviços
            </span>
          </div>

          <h5 className='mt-4 ffSoft fw500 textContrastColor'>
            Minhas Contas & Assinaturas
          </h5>
          {userTenants && userTenants.length > 0 && tenantsTable}
          <h6 className='mt-5 mb-2 ffSoft fw500 d-flex'>
            <span>Nova Conta</span>
            <span>
              <IconsUI
                info={ui.expand.more}
                clsnm='hov-green svg14'
                click={() => {
                  const form = document.querySelector('form');
                  form.classList.toggle('divHide');
                }}
              />
            </span>
          </h6>
          <div className='d-flex justify-content-center w100'>
            <Form
              data-bs-theme={theme}
              action=''
              className='w100 wpMax600 divHide align-content-start bgMenu p-5'
            >
              <Form.Label
                htmlFor='tenTenant'
                className='input-label fs10 textContrastLow my-0'
              >
                Conta (apenas letras)
              </Form.Label>
              <Form.Control
                required
                type='text'
                id='tenTenant'
                size='sm'
                className='mb-2'
                onKeyUp={editConta}
                onBlur={async (e) => {
                  await checkTenant(e);
                }}
              />
              <p className='text-center cRed fw500'>{tenMessage}</p>

              <Form.Label
                htmlFor='tenNome'
                className='input-label fs10 textContrastLow my-0'
              >
                Nome
              </Form.Label>
              <Form.Control
                required
                type='text'
                size='sm'
                id='tenNome'
                className='mb-2'
                onChange={({ target: { value } }) => setTenNome(value)}
              />

              <Form.Label
                htmlFor='tenEmail'
                className='input-label fs10 textContrastLow my-0'
              >
                E-Mail
              </Form.Label>
              <Form.Control
                required
                type='text'
                size='sm'
                id='tenEmail'
                name='tenEmail'
                aria-describedby='tenEmail'
                onChange={({ target: { value } }) => setTenEmail(value)}
                className='mb-2'
              />

              <Form.Label
                htmlFor='tenTelefone'
                className='input-label fs10 textContrastLow my-0'
              >
                WhatsApp
              </Form.Label>

              <Form.Control
                required
                type='text'
                as={IMaskInput}
                mask='(00) 00000-0000'
                id='tenTelefone'
                size='sm'
                className='mb-2'
                onChange={({ target: { value } }) => setTenTelefone(value)}
              />

              <Form.Label
                htmlFor='tenEndereco'
                className='input-label fs10 textContrastLow my-0'
              >
                Endereço
              </Form.Label>
              <Form.Control
                required
                type='text'
                id='tenEndereco'
                size='sm'
                className='mb-2'
                onChange={({ target: { value } }) => setTenEndereco(value)}
              />

              <Form.Label
                htmlFor='tenCidade'
                className='input-label fs10 textContrastLow my-0'
              >
                Cidade-UF
              </Form.Label>
              <Form.Control
                required
                type='text'
                size='sm'
                id='tenCidade'
                className='mb-2'
                onChange={({ target: { value } }) => setTenCidade(value)}
              />

              <div className='d-flex flex-row justify-content-around my-2'>
                <Button
                  variant='primary'
                  id='submitTenant'
                  onClick={(event) => {
                    event.preventDefault();
                    createTenant();
                  }}
                >
                  Registrar
                </Button>
              </div>
            </Form>
          </div>
        </main>
      }
    />
  );
}
